import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  SubTitle,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

import api from "../api/api";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  SubTitle,
  Title,
  Tooltip,
  Legend
)

const styles: any = {
  root: {
    width: "100%",
    overflowX: "auto",
  },
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: '# of Active Engagements',
    },
    subtitle: {
      display: true,
      text: "Measured at the beginning of the quarter",
    }
  },
};

const labels = ["Q1 '21", "Q2 '21", "Q3 '21", "Q4 '21", "Q1 '22", "Q2 '22", "Q3 '22", "Q4 '22", "Q1 '23"];

const targetLineColor = {
  borderColor: 'rgba(255, 165, 0, 1)',
  backgroundColor: 'rgba(255, 165, 0, 1)',
}

type Kpi = {
  actual: Number[];
  target: Number[];
}

type KpiChartData = {
  activeEngagements: Kpi;
}

function AdminKPIsPage(props: any) {
  // const { classes } = props;
  const [kpiChartData, setKpiChartData] = useState<KpiChartData | null>(null);
  useEffect(() => {
    api.admin.fetchKpis()
      .then((response: KpiChartData) => {
        setKpiChartData(response);
      })
  }, []);

  return (
    <div>
      <Typography variant="h4" gutterBottom component="h2">
        KPIs
      </Typography>
      <Grid container spacing={4} style={{ marginBottom: "20px" }}>
        <Grid item sm={6}>

          <Line
            options={options}
            data={{
              labels,
              datasets: [
                {
                  label: 'Actual',
                  data: kpiChartData?.activeEngagements.actual,
                  borderColor: 'rgba(0, 255, 0, 1)',
                  backgroundColor: 'rgba(0, 255, 0, 1)',
                },
                {
                  label: 'Target',
                  data: kpiChartData?.activeEngagements.target,
                  ...targetLineColor,
                },
              ]
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(AdminKPIsPage);
