import React, { useState, useEffect } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { createStyles } from "@material-ui/core";

import { StoreState } from "../redux/configure-store";
import { parseRouteIdFromProps, routePaths } from "../routing/route-paths";
import TopBar from "../user/components-layout/top-bar";
import MenuDrawer from "../user/components-layout/menu-drawer";
import AdminMenuList from "./AdminMenuList";
import RegisterPage from "./register-page";
import AppStatesTable from "./app-states/app-states-table";
import AdminUsersTable from "./users/admin-users-table";
import AdminUsersView from "./users/admin-users-view";
import AdminInvoicesTable from "./invoices/admin-invoices-table";
import AdminInvoicesCreate from "./invoices/AdminInvoicesCreate";
import AdminTimesheetsTablePage from "./timesheets/AdminTimesheetsTablePage";
import AdminTimesheetsView from "./timesheets/AdminTimesheetsView";
import AdminEngagementsTable from "./engagements/AdminEngagementsTable";
import AdminEngagementsCreate from "./engagements/admin-engagements-create";
import AdminEngagementsView from "./engagements/AdminEngagementsView";
import AdminKPIsPage from "./AdminKPIsPage";

import { switchMenuDrawer } from "../user/user-actions";
import {
  navigateToAdminTimesheetsViewPage,
  navigateToAdminUsersViewPage,
  navigateToAdminEngagementsViewPage,
  navigateToAdminEngagementsCreateInvoice,
} from "../routing/route-actions";
import {
  fetchAppStatesForAdmin,
  fetchEngagementsForAdmin,
  fetchInvoicesForAdmin,
  fetchTimesheetsForAdmin,
  fetchUsersForAdmin,
  setAppStateWorkerProcessLock,
} from "./admin-actions";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      paddingLeft: "100px",
      height: "100vh",
      overflow: "auto",
    },
    h5: {
      marginBottom: theme.spacing(2),
    },
  });

function Dashboard(props: any) {
  const {
    appStates,
    classes,
    pathname,
    engagements,
    switchMenuDrawer,
    isMenuDrawerOpen,
    isHideUnavailableUsers,
    fetchInvoicesForAdmin: _fetchInvoicesForAdmin,
    fetchTimesheetsForAdmin: _fetchTimesheetsForAdmin,
    fetchUsersForAdmin: _fetchUsersForAdmin,
    fetchAppStatesForAdmin: _fetchAppStatesForAdmin,
    fetchEngagementsForAdmin: _fetchEngagementsForAdmin,
    navigateToAdminEngagementsViewPage: _navigateToAdminEngagementsViewPage,
    navigateToAdminTimesheetsViewPage: _navigateToAdminTimesheetsViewPage,
    navigateToAdminUsersViewPage: _navigateToAdminUsersViewPage,
    navigateToAdminEngagementsCreateInvoice: _navigateToAdminEngagementsCreateInvoice,
    setAppStateWorkerProcessLock: _setAppStateWorkerProcessLock,
    timesheets,
    users,
  } = props;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      _fetchInvoicesForAdmin(),
      _fetchTimesheetsForAdmin(),
      _fetchUsersForAdmin(),
      _fetchAppStatesForAdmin(),
      _fetchEngagementsForAdmin(),
    ])
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    _fetchInvoicesForAdmin,
    _fetchUsersForAdmin,
    _fetchTimesheetsForAdmin,
    _fetchAppStatesForAdmin,
    _fetchEngagementsForAdmin,
    setIsLoading
  ]);


  return (
    <div className={classes.root}>
      <TopBar />
      <MenuDrawer
        pathname={pathname}
        open={isMenuDrawerOpen}
        handleDrawerClose={switchMenuDrawer}
        ListComponent={AdminMenuList}
      />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Switch>
          <Redirect
            exact
            path={routePaths.admin.root}
            to={routePaths.admin.kpis}
          />
          <Route
            path={routePaths.admin.kpis}
            render={(props) => (
              <AdminKPIsPage
                {...props}
              />
            )}
          />
          <Route
            path={routePaths.admin.appStates}
            render={(props) => (
              <AppStatesTable
                {...props}
                appStates={appStates}
                setAppStateWorkerProcessLock={
                  _setAppStateWorkerProcessLock
                }
              />
            )}
          />
          <Route
            exact
            path={routePaths.admin.users}
            render={(props) => (
              <AdminUsersTable
                {...props}
                isHideUnavailableUsers={isHideUnavailableUsers}
                users={users}
                navigateToAdminUsersViewPage={
                  _navigateToAdminUsersViewPage
                }
              />
            )}
          />
          <Route
            exact
            path={routePaths.admin.timesheets}
            render={(props) => (
              <AdminTimesheetsTablePage
                {...props}
                navigateToAdminTimesheetsViewPage={
                  _navigateToAdminTimesheetsViewPage
                }
              />
            )}
          />
          <Route
            exact
            path={routePaths.admin.timesheetsView}
            render={(props) => (
              <AdminTimesheetsView
                {...props}
                selectedTimesheet={timesheets.find(
                  (timesheet: any) =>
                    timesheet.id === parseRouteIdFromProps(props)
                )}
              />
            )}
          />
          <Route
            exact
            path={routePaths.admin.invoices}
            render={(props) => <AdminInvoicesTable {...props} />}
          />
          <Route
            exact
            path={routePaths.admin.engagementsCreateInvoice}
            render={(props) => (
              <AdminInvoicesCreate
                {...props}
                isLoading={isLoading}
                engagement={engagements.find(
                  (engagement: any) =>
                    engagement.id === parseRouteIdFromProps(props)
                )}
              />
            )}
          />
          <Route
            exact
            path={routePaths.admin.usersCreateEngagement}
            render={(props) => (
              <AdminEngagementsCreate
                {...props}
                user={users.find(
                  (user: any) => user.id === parseRouteIdFromProps(props)
                )}
              />
            )}
          />
          <Route
            exact
            path={routePaths.admin.usersView}
            render={(props) => (
              <AdminUsersView
                {...props}
                selectedUser={users.find(
                  (user: any) => user.id === parseRouteIdFromProps(props)
                )}
              />
            )}
          />
          <Route
            exact
            path={routePaths.admin.engagements}
            render={(props) => (
              <AdminEngagementsTable
                {...props}
                navigateToAdminEngagementsViewPage={
                  _navigateToAdminEngagementsViewPage
                }
              />
            )}
          />
          <Route
            exact
            path={routePaths.admin.engagementsView}
            render={(props) => <AdminEngagementsView
              {...props}
              navigateToAdminEngagementsCreateInvoice={
                _navigateToAdminEngagementsCreateInvoice
              }
              engagement={engagements.find(
                (engagement: any) => engagement.id === parseRouteIdFromProps(props)
              )}
            />
            }
          />
          <Route
            path={routePaths.admin.register}
            render={(props) => (<RegisterPage {...props} />)}
          />
        </Switch>
      </main>
    </div>
  );
}

const mapStateToProps = (storeState: StoreState) => ({
  pathname: storeState.router.location.pathname,
  appStates: storeState.admin.appStates,
  engagements: storeState.admin.engagements,
  isHideUnavailableUsers: storeState.admin.isHideUnavailableUsers,
  isMenuDrawerOpen: storeState.user.isMenuDrawerOpen,
  timesheets: storeState.admin.timesheets,
  user: storeState.authentication.user,
  users: storeState.admin.users,
});

export default connect(mapStateToProps, {
  fetchAppStatesForAdmin,
  fetchEngagementsForAdmin,
  fetchInvoicesForAdmin,
  fetchTimesheetsForAdmin,
  fetchUsersForAdmin,
  switchMenuDrawer,
  navigateToAdminTimesheetsViewPage,
  navigateToAdminUsersViewPage,
  navigateToAdminEngagementsViewPage,
  navigateToAdminEngagementsCreateInvoice,
  setAppStateWorkerProcessLock,
})(withStyles(styles)(Dashboard));
