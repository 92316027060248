import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";

import { formatShortDate, getInitials } from "../common/utils";
import { StoreState } from "../redux/configure-store";

const styles = (theme: Theme) => ({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

class EngagementListItem extends React.Component<any> {
  render() {
    const { classes, engagement, onEngagementClick } = this.props;
    return (
      <div key={engagement.id}>
        <ListItem
          onClick={() => {
            if (onEngagementClick) {
              onEngagementClick(engagement.publicId);
            }
          }}
          divider={true}
          alignItems="flex-start"
          style={{ paddingLeft: "0px", cursor: "pointer" }}
        >
          <ListItemAvatar>
            <Avatar>{getInitials(engagement.clientName)}</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={engagement.clientName}
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textPrimary"
                >
                  {engagement.endDate ? (
                    <span style={{ color: "red" }}>
                      Ended {formatShortDate(engagement.endDate)}
                    </span>
                  ) : (
                    `${engagement.numberOfHoursPerWeek} hrs/week`
                  )}{" "}
                  | {engagement.currency}
                  {engagement.hourlyRate} /hr
                </Typography>{" "}
                {engagement.shortDescription}{" "}
                {/* eslint-disable-next-line */}
                <a style={{ paddingLeft: "10px" }} href="#">
                  View
                </a>
              </React.Fragment>
            }
          />
        </ListItem>
      </div>
    );
  }
}

const mapStateToProps = (storeState: StoreState) => ({});

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(EngagementListItem));
