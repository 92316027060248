import React, { useState } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Input from "@material-ui/core/Input";

import { StoreState } from "../../redux/configure-store";
import ListHeader from "../../common/components/list-header";
import {
  calculateTotalNumberOfHoursForTimesheet,
  stringSortCompare,
} from "../../common/utils";
import { Timesheet } from "../types";
import { formatShortDate } from "../../common/utils";
import { primary } from "../../theme/theme"

const styles: any = {
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
    marginBottom: "40px",
  },
  tableContainer: {
    height: 320,
  },
};

function AdminTimesheetsTable(props: any) {
  const { classes, onRowClick, selectedTimesheetIds = [] } = props;

  const [timesheets, setTimesheets] = useState([]);
  const [sortColumnName, setSortColumnName] = useState("Period End Date");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

  React.useEffect(() => {
    setTimesheets(props.timesheets);
  }, [props.timesheets]);

  const columnNames = [
    { name: "Developer Name", isSortable: true },
    { name: "Client Name", isSortable: true },
    { name: "Engagement Id" },
    { name: "Notes" },
    { name: "Total Hours" },
    { name: "Period Start Date" },
    { name: "Period End Date", isSortable: true },
    { name: "Submitted At", isSortable: true },
  ];
  const changeSort = (columnName: string) => {
    let newSortDirection: "asc" | "desc" = "desc";
    if (columnName === sortColumnName) {
      newSortDirection = sortDirection === "desc" ? "asc" : "desc";
    }
    setSortDirection(newSortDirection);
    setSortColumnName(columnName);
    let sortedTimesheets;
    switch (columnName) {
      case "Developer Name":
        sortedTimesheets = [...timesheets].sort((a: any, b: any) => {
          if (newSortDirection === "asc") {
            return stringSortCompare(a.user.firstName, b.user.firstName);
          }
          return stringSortCompare(b.user.firstName, a.user.firstName);
        });
        setTimesheets(sortedTimesheets);
        break;
      case "Client Name":
        sortedTimesheets = [...timesheets].sort((a: any, b: any) => {
          if (newSortDirection === "asc") {
            return stringSortCompare(
              a.engagement.clientName,
              b.engagement.clientName
            );
          }
          return stringSortCompare(
            b.engagement.clientName,
            a.engagement.clientName
          );
        });
        setTimesheets(sortedTimesheets);
        break;
      case "Period End Date":
        sortedTimesheets = [...timesheets].sort((a: any, b: any) => {
          if (newSortDirection === "asc") {
            return (
              new Date(a.endDate).getTime() - new Date(b.endDate).getTime()
            );
          }
          return new Date(b.endDate).getTime() - new Date(a.endDate).getTime();
        });
        setTimesheets(sortedTimesheets);
        break;
      case "Submitted At":
        sortedTimesheets = [...timesheets].sort((a: any, b: any) => {
          if (newSortDirection === "asc") {
            return (
              new Date(a.submittedAt).getTime() -
              new Date(b.submittedAt).getTime()
            );
          }
          return (
            new Date(b.submittedAt).getTime() -
            new Date(a.submittedAt).getTime()
          );
        });
        setTimesheets(sortedTimesheets);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <Table className={classes.table}>
        <ListHeader
          columns={columnNames}
          sortDirection={sortDirection}
          sortColumnName={sortColumnName}
          onColumnClick={(columnName: string) => changeSort(columnName)}
        />
        <TableBody>
          {((timesheets || []) as Timesheet[]).map((timesheet, index) => {
            let background = index % 2 === 0 ? "#eee" : "white";
            if (selectedTimesheetIds.includes(timesheet.id)) {
              background = primary.light
            }
            const totalNumberOfHours =
              calculateTotalNumberOfHoursForTimesheet(
                timesheet.details,
                timesheet.timeFormat,
              );
            return (
              <TableRow
                style={{ cursor: "pointer", background }}
                key={timesheet.id}
                onClick={() => {
                  onRowClick(timesheet.id)
                }}
              >
                <TableCell component="th" scope="row">
                  {timesheet.user.firstName} {timesheet.user.lastName}
                </TableCell>
                <TableCell>{timesheet.engagement.clientName}</TableCell>
                <TableCell>{timesheet.engagement.id}</TableCell>
                <TableCell>
                  <Input
                    value={timesheet.notes}
                    onClick={(e: any) => e.stopPropagation()}
                    multiline={true}
                    rows={4}
                  />
                </TableCell>
                <TableCell>
                  {totalNumberOfHours} ({timesheet.timeFormat})
                </TableCell>
                <TableCell>
                  {formatShortDate(timesheet.startDate)}
                </TableCell>
                <TableCell>{formatShortDate(timesheet.endDate)}</TableCell>
                <TableCell>
                  {formatShortDate(timesheet.submittedAt)}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

const mapStateToProps = (storeState: StoreState) => ({
});

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(AdminTimesheetsTable));
