import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import AdminEngagementsTableRow from "./admin-engagements-table-row";
import { endEngagement } from "../admin-actions";
import { StoreState } from "../../redux/configure-store";
import ListHeader from "../../common/components/list-header";
import { isEngagementActive } from '../../common/models';

import { Engagement } from "../types";

const styles: any = {
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
    marginBottom: "40px",
  },
  tableContainer: {
    height: 320,
  },
};

function EngagementsTable(props: any) {
  const {
    classes,
    engagements,
    navigateToAdminEngagementsViewPage,
    endEngagement: _endEngagement
  } = props;
  const columnNames = [
    "Client Name",
    "Developer",
    "Currency",
    "Hourly Rate",
    "Tracking Type",
    "Number of Hours per week",
    "Short Description",
    "Description",
    "Last Timesheet Period Submitted",
    "Start Date",
    "End Date",
  ];

  const activeEngagements = (engagements || []).filter(isEngagementActive);
  const numberOfActiveEngagements = activeEngagements.length;
  const timesheetPeriodMap = new Map<number, any>();

  // determining the last timesheet that was submitted for the engagement
  activeEngagements.forEach((e: Engagement) => {
    const timesheet = props.timesheets.find((t: any) => t.engagement.id === e.id && !!t.submittedAt);
    timesheetPeriodMap.set(e.id, timesheet && timesheet.endDate);
  });

  return (
    <div>
      <Grid container spacing={4} style={{ marginBottom: "20px" }}>
        <Grid item sm={6}>
          <Typography variant="h4" gutterBottom component="h2">
            Engagements
          </Typography>
        </Grid>
        <Grid item sm={6}>
          <Typography variant="body1" gutterBottom component="h4">
            No. of Active Engagements: {numberOfActiveEngagements}
          </Typography>
        </Grid>
      </Grid>
      <div className={classes.tableContainer}>
        <Paper elevation={2} className={classes.root}>
          <Table className={classes.table}>
            <ListHeader columns={columnNames} />
            <TableBody>
              {(engagements || []).map((engagement: Engagement) => {
                return (
                  <AdminEngagementsTableRow
                    lastTimesheetSubmittedAt={timesheetPeriodMap.get(
                      engagement.id
                    )}
                    endEngagement={_endEngagement}
                    engagement={engagement}
                    navigateToAdminEngagementsViewPage={
                      navigateToAdminEngagementsViewPage
                    }
                  />
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </div>
    </div>
  );
}

const mapStateToProps = (storeState: StoreState) => ({
  engagements: storeState.admin.engagements,
  timesheets: storeState.admin.timesheets,
});

export default connect(mapStateToProps, {
  endEngagement,
})(withStyles(styles)(EngagementsTable));
