import api from "../api/api";
import { Engagement, Invoice } from "../common/interfaces";
import {
  navigateToAdminEngagements,
  navigateToAdminInvoices,
} from "../routing/route-actions";

export const FETCH_APP_STATES_SUCCESS = "FETCH_APP_STATES_SUCCESS";
export const ADMIN_FETCH_ENGAGEMENTS_SUCCESS =
  "ADMIN_FETCH_ENGAGEMENTS_SUCCESS";
export const ADMIN_FETCH_INVOICES_SUCCESS = "ADMIN_FETCH_INVOICES_SUCCESS";
export const ADMIN_FETCH_TIMESHEETS_SUCCESS = "ADMIN_FETCH_TIMESHEETS_SUCCESS";
export const ADMIN_FETCH_USERS_SUCCESS = "ADMIN_FETCH_USERS_SUCCESS";
export const ADMIN_EDIT_USER_START = "ADMIN_EDIT_USER_START";
export const ADMIN_EDIT_USER_SUCCESS = "ADMIN_EDIT_USER_SUCCESS";
export const ADMIN_EDIT_USER_FAIL = "ADMIN_EDIT_USER_FAIL";

export const ADMIN_SET_SELECTED_TIMESHEETS = "ADMIN_SET_SELECTED_TIMESHEETS";
export const ADMIN_UNSET_SELECTED_TIMESHEETS = "ADMIN_UNSET_SELECTED_TIMESHEETS";

export const ADMIN_TOGGLE_HIDE_UNAVAILABLE_USERS =
  "ADMIN_TOGGLE_HIDE_UNAVAILABLE_USERS";

const dispatchFetchUsersSuccess = (dispatch: Function, users: any) => {
  dispatch({ type: ADMIN_FETCH_USERS_SUCCESS, payload: { users } });
};

const dispatchFetchAppStatesSuccess = (dispatch: Function, appStates: any) => {
  dispatch({ type: FETCH_APP_STATES_SUCCESS, payload: { appStates } });
};

const dispatchFetchEngagementsSuccess = (
  dispatch: Function,
  engagements: any
) => {
  dispatch({ type: ADMIN_FETCH_ENGAGEMENTS_SUCCESS, payload: { engagements } });
};

const dispatchFetchTimesheetsSuccess = (
  dispatch: Function,
  timesheets: any
) => {
  dispatch({ type: ADMIN_FETCH_TIMESHEETS_SUCCESS, payload: { timesheets } });
};

const dispatchFetchInvoicesSuccess = (dispatch: Function, invoices: any) => {
  dispatch({ type: ADMIN_FETCH_INVOICES_SUCCESS, payload: { invoices } });
};

const dispatchAdminEditUserStart = (dispatch: Function) => {
  dispatch({ type: ADMIN_EDIT_USER_START, payload: {} });
};
const dispatchAdminEditUserSuccess = (dispatch: Function) => {
  dispatch({ type: ADMIN_EDIT_USER_SUCCESS, payload: {} });
};
const dispatchAdminEditUserFail = (dispatch: Function) => {
  dispatch({ type: ADMIN_EDIT_USER_FAIL, payload: {} });
};

const dispatchAdminToggleHideUnavailableUsers = (dispatch: Function) => {
  dispatch({ type: ADMIN_TOGGLE_HIDE_UNAVAILABLE_USERS });
};

export const dispatchAdminSetSlectedTimesheets = (timesheetIds: number[]) => (dispatch: Function) => {
  dispatch({ type: ADMIN_SET_SELECTED_TIMESHEETS, payload: { timesheetIds } });
};

export const dispatchAdminUnsetSlectedTimesheets = () => (dispatch: Function) => {
  dispatch({ type: ADMIN_UNSET_SELECTED_TIMESHEETS, payload: {} });
};

export const toggleHideUnavailableUsersForAdmin = () => {
  return async (dispatch: Function) => {
    dispatchAdminToggleHideUnavailableUsers(dispatch);
  };
};

export const setAppStateWorkerProcessLock = (isLockWorkerProcess: boolean) => {
  return async (dispatch: Function) => {
    await api.admin.setAppStateWorkerProcessLock(isLockWorkerProcess);
    await fetchAppStatesForAdmin()(dispatch);
  };
};

export const fetchAppStatesForAdmin = () => {
  return async (dispatch: Function) => {
    const appStates = await api.admin.fetchAppStates();
    dispatchFetchAppStatesSuccess(dispatch, appStates);
  };
};

/** Users */

export const updateUserForAdmin = (updatedUserForm: any) => {
  return async (dispatch: Function) => {
    try {
      dispatchAdminEditUserStart(dispatch);
      await api.admin.updateUserForAdmin(updatedUserForm);
      await fetchUsersForAdmin()(dispatch);
      dispatchAdminEditUserSuccess(dispatch);
      return true;
    } catch (error) {
      dispatchAdminEditUserFail(dispatch);
      return false;
    }
  };
};

export const fetchUsersForAdmin = () => {
  return async (dispatch: Function) => {
    try {
      const users = await api.admin.fetchUsersForAdmin();
      dispatchFetchUsersSuccess(dispatch, users);
      return true;
    } catch (e) {
      return false;
    }
  };
};

/** Engagements */

export const fetchEngagementsForAdmin = () => {
  return async (dispatch: Function) => {
    const engagements = await api.admin.fetchEngagements();
    dispatchFetchEngagementsSuccess(dispatch, engagements);
  };
};

export const createEngagement = (engagement: Engagement) => {
  return async (dispatch: Function) => {
    try {
      await api.admin.createEngagement(engagement);
      fetchEngagementsForAdmin()(dispatch);
      navigateToAdminEngagements()(dispatch);
    } catch (e) {
      //
    }
  };
};

export const endEngagement = (publicId: string) => {
  return async (dispatch: Function) => {
    try {
      await api.admin.endEngagement(publicId);
      fetchEngagementsForAdmin()(dispatch);
      navigateToAdminEngagements()(dispatch);
    } catch (e) {
      alert("Error creating invoice");
    }
  };
};

/** invoices */

export const fetchInvoicesForAdmin = () => {
  return async (dispatch: Function) => {
    const invoices = await api.admin.fetchInvoicesForAdmin();
    dispatchFetchInvoicesSuccess(dispatch, invoices);
  };
};

export const createInvoice = (invoice: Invoice) => {
  return async (dispatch: Function) => {
    try {
      await api.admin.createInvoice(invoice);
      fetchInvoicesForAdmin()(dispatch);
      navigateToAdminInvoices()(dispatch);
    } catch (e) {
      alert("Error creating invoice");
    }
  };
};

export const markInvoiceAsPaid = (publicId: string) => {
  return async (dispatch: Function) => {
    try {
      await api.admin.markInvoiceAsPaid(publicId);
      fetchInvoicesForAdmin()(dispatch);
      navigateToAdminInvoices()(dispatch);
    } catch (e) {
      alert("Error creating invoice");
    }
  };
};

/** Timesheets */

export const fetchTimesheetsForAdmin = () => {
  return async (dispatch: Function) => {
    const timesheets = await api.admin.fetchTimesheetsForAdmin();
    dispatchFetchTimesheetsSuccess(dispatch, timesheets);
  };
};
