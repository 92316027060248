import { push } from "connected-react-router";

import { routePaths } from "../routing/route-paths";
import { navigateToDashboardPage } from "../routing/route-actions";
import api from "../api/api";

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const SWITCH_MENU_DRAWER = "SWITCH_MENU_DRAWER";
export const UPDATE_USER_SETTINGS_SUCCESS = "UPDATE_USER_SETTINGS_SUCCESS";

export const UPDATE_USER_LOGO_IMAGE = "UPDATE_USER_LOGO_IMAGE";
export const UPDATE_USER_LOGO_IMAGE_FAIL = "UPDATE_USER_LOGO_IMAGE_FAIL";
export const UPDATE_USER_PROFILE_IMAGE = "UPDATE_USER_PROFILE_IMAGE";

export const FETCH_ENGAGEMENTS_SUCCESS = "FETCH_ENGAGEMENTS_SUCCESS";
export const FETCH_INVOICES_SUCCESS = "FETCH_INVOICES_SUCCESS";

export const SHOW_SUCCESS_SNACKBAR = "SHOW_SUCCESS_SNACKBAR";

export const updateUserSettings = (userId: number) => {
  return async (dispatch: Function) => {
    try {
      const user = await api.users.updateUserSettings(userId);
      dispatch({
        type: UPDATE_USER_SETTINGS_SUCCESS,
        payload: {
          user,
        },
      });
      navigateToDashboardPage()(dispatch);
    } catch (error) {
      //
    }
  };
};

export const fetchEngagements = () => {
  return async (dispatch: Function) => {
    try {
      const engagements = await api.users.fetchEngagements();
      dispatch({
        type: "FETCH_ENGAGEMENTS_SUCCESS",
        payload: engagements,
      });
    } catch (e) {
      //
    }
  };
};

export const fetchInvoices = () => {
  return async (dispatch: Function) => {
    try {
      const invoices = await api.users.fetchInvoices();
      dispatch({
        type: "FETCH_INVOICES_SUCCESS",
        payload: invoices,
      });
    } catch (e) {
      //
    }
  };
};

export const saveTimesheetDraft = (
  timesheetId: number,
  timesheetDetails: any,
  notes: string,
  timeFormat: "decimals" | "minutes",
  isSubmitting = false
) => {
  return async (dispatch: Function) => {
    try {
      await api.users.updateTimesheet(
        timesheetId,
        timesheetDetails,
        notes,
        timeFormat,
        isSubmitting
      );
      if (isSubmitting) {
        dispatch({
          type: SHOW_SUCCESS_SNACKBAR,
          payload: {
            isOpen: true
          }
        });
      }
      navigateToDashboardPage()(dispatch);
      setTimeout(() => {
        dispatch({
          type: SHOW_SUCCESS_SNACKBAR,
          payload: {
            isOpen: false
          }
        });
      }, 4000)
    } catch (e) {
      //
    }
  };
};

export const navigateToSettingsPage = () => (dispatch: Function) =>
  dispatch(push(routePaths.user.settings));

export const navigateToChangePasswordPage = () => (dispatch: Function) =>
  dispatch(push(routePaths.auth.changePassword));

export const switchMenuDrawer = () => (dispatch: Function) =>
  dispatch({
    type: SWITCH_MENU_DRAWER,
    payload: {},
  });
