import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Input from "@material-ui/core/Input";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormLabel";

import { StoreState } from "../../redux/configure-store";
import { Engagement, PaymentMethod } from "../types";
import { formatCurrencyAmount } from "../../common/utils";
import AdminTimesheetsTable from './AdminTimesheetsTable'

const styles: any = {
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
    marginBottom: "40px",
  },
  tableContainer: {
    height: 320,
  },
};

type PaymentMethodStats = {
  paymentMethod: PaymentMethod;
  totalAmountOwed: number;
  numberOfActiveEngagements: number;
};

function AdminTimesheetsTablePage(props: any) {
  const { classes, engagements } = props;

  const defaultPaymentMethodStats: PaymentMethodStats = {
    paymentMethod: "wise",
    totalAmountOwed: 0,
    numberOfActiveEngagements: 0,
  };

  const [numberOfDays, setNumberOfDays] = useState(22);
  const [numberOfDaysError, setNumberOfDaysError] = useState(false);
  const [numberOfActiveEngagements, setNumberOfActiveEngagements] = useState(0);
  const [totalAmountOwed, setTotalAmountOwed] = useState(0);
  const [bmoUsdStats, setBmoUsdStats] = useState<PaymentMethodStats>(
    defaultPaymentMethodStats
  );
  const [bitcoinStats, setBitcoinStats] = useState<PaymentMethodStats>(
    defaultPaymentMethodStats
  );
  const [wiseStats, setWiseStats] = useState<PaymentMethodStats>(
    defaultPaymentMethodStats
  );
  const [stablecoinStats, setStablecoinStats] = useState<PaymentMethodStats>(
    defaultPaymentMethodStats
  );

  function filterPaymentMethod(paymentMethod: PaymentMethod) {
    return function (engagement: Engagement): boolean {
      return engagement.user.paymentMethod === paymentMethod;
    };
  }

  const reduceEngagementPaymentAmount = useCallback((_numberOfDays: number) => {
    return function (acc: number, engagement: Engagement): number {
      return (
        ((engagement.hourlyRate * engagement.numberOfHoursPerWeek) / 5) *
        numberOfDays +
        acc
      );
    };
  }, [numberOfDays]);

  const setStats = useCallback(() => {
    if (!engagements || !engagements.length) {
      setNumberOfActiveEngagements(0);
      return;
    }
    const activeEngagements = engagements.filter((e: Engagement) => !e.endDate);
    setNumberOfActiveEngagements(activeEngagements.length);

    setTotalAmountOwed(
      activeEngagements.reduce(reduceEngagementPaymentAmount(numberOfDays), 0)
    );

    setBmoUsdStats({
      paymentMethod: "bmoUsd",
      totalAmountOwed: activeEngagements
        .filter(filterPaymentMethod("bmoUsd"))
        .reduce(reduceEngagementPaymentAmount(numberOfDays), 0),
      numberOfActiveEngagements: activeEngagements.filter(
        filterPaymentMethod("bmoUsd")
      ).length,
    });

    setWiseStats({
      paymentMethod: "wise",
      totalAmountOwed: activeEngagements
        .filter(filterPaymentMethod("wise"))
        .reduce(reduceEngagementPaymentAmount(numberOfDays), 0),
      numberOfActiveEngagements: activeEngagements.filter(
        filterPaymentMethod("wise")
      ).length,
    });

    setBitcoinStats({
      paymentMethod: "bitcoin",
      totalAmountOwed: activeEngagements
        .filter(filterPaymentMethod("bitcoin"))
        .reduce(reduceEngagementPaymentAmount(numberOfDays), 0),
      numberOfActiveEngagements: activeEngagements.filter(
        filterPaymentMethod("bitcoin")
      ).length,
    });

    setStablecoinStats({
      paymentMethod: "stablecoin",
      totalAmountOwed: activeEngagements
        .filter(filterPaymentMethod("stablecoin"))
        .reduce(reduceEngagementPaymentAmount(numberOfDays), 0),
      numberOfActiveEngagements: activeEngagements.filter(
        filterPaymentMethod("stablecoin")
      ).length,
    });
  }, [engagements, numberOfDays, reduceEngagementPaymentAmount]);

  React.useEffect(() => {
    setStats();
  }, [setStats]);

  function onChangeNumberOfDays(e: any) {
    const newValue = e.target.value;
    if (isNaN(newValue)) {
      setNumberOfDaysError(true);
      return;
    }
    setNumberOfDaysError(false);
    setNumberOfDays(e.target.value);
  }

  return (
    <div>
      <Grid container spacing={4} style={{ marginBottom: "20px" }}>
        <Grid item sm={6}>
          <Typography variant="h4" gutterBottom component="h2">
            Timesheets
          </Typography>
        </Grid>
        <Grid item sm={2}>
          <Typography variant="h5" gutterBottom component="h5">
            <b>Projections</b>
          </Typography>
          <FormControl margin="normal" fullWidth error={false}>
            <InputLabel htmlFor="numberOfDays" required>
              Num Days
            </InputLabel>
            <Input
              id="numberOfDays"
              name="numberOfDays"
              autoFocus
              autoComplete="off"
              value={numberOfDays}
              onChange={onChangeNumberOfDays}
            />
            {numberOfDaysError && (
              <FormHelperText>Invalid Number of Days Amount</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item sm={4}>
          <Typography variant="body1" gutterBottom component="h4">
            Total: {formatCurrencyAmount(totalAmountOwed)} <br /> No. of
            Engagements: {numberOfActiveEngagements}
          </Typography>
          <Typography variant="body1" gutterBottom component="h4">
            BMO USD: {formatCurrencyAmount(bmoUsdStats.totalAmountOwed)} <br />{" "}
            No. of Engagements {bmoUsdStats.numberOfActiveEngagements}
          </Typography>
          <Typography variant="body1" gutterBottom component="h4">
            Bitcoin: {formatCurrencyAmount(bitcoinStats.totalAmountOwed)} <br />{" "}
            No. of Engagements {bitcoinStats.numberOfActiveEngagements}
          </Typography>
          <Typography variant="body1" gutterBottom component="h4">
            Stablecoin: {formatCurrencyAmount(stablecoinStats.totalAmountOwed)}{" "}
            <br /> No. of Engagements{" "}
            {stablecoinStats.numberOfActiveEngagements}
          </Typography>
          <Typography variant="body1" gutterBottom component="h4">
            Wise: {formatCurrencyAmount(wiseStats.totalAmountOwed)} <br /> No.
            of Engagements {wiseStats.numberOfActiveEngagements}
          </Typography>
        </Grid>
      </Grid>
      <div className={classes.tableContainer}>
        <Paper elevation={2} className={classes.root}>
          <AdminTimesheetsTable
            onRowClick={props.navigateToAdminTimesheetsViewPage}
            isDisableRowClick={false}
            timesheets={props.timesheets}
          >
          </AdminTimesheetsTable>
        </Paper>
      </div>
    </div>
  );
}

const mapStateToProps = (storeState: StoreState) => ({
  engagements: storeState.admin.engagements,
  timesheets: storeState.admin.timesheets,
});

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(AdminTimesheetsTablePage));
