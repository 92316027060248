import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import Table from "@material-ui/core/Table";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { StoreState } from "../../redux/configure-store";
import ListHeader from "../../common/components/list-header";
import { formatShortDate } from "../../common/utils";
import { toggleHideUnavailableUsersForAdmin } from "../admin-actions";
import { User } from "../types";

const styles: any = {
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
    marginBottom: "40px",
  },
  tableContainer: {
    height: 320,
    marginTop: "10px",
  },
};

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

function UsersTable(props: any) {
  const {
    classes,
    isHideUnavailableUsers,
    navigateToAdminUsersViewPage,
    users,
  } = props;

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);

  const filterUsers = useCallback(() => {
    let usersToDisplay = isHideUnavailableUsers
      ? users.filter((u: any) => u.numberOfHoursPerWeekAvailable !== 0)
      : users;
    if (!searchQuery) {
      return usersToDisplay;
    }

    const searchTerms = searchQuery.split(" ");

    return usersToDisplay.filter((u: User) => {
      let numberOfMatches = 0;
      searchTerms.forEach((searchTerm) => {
        if (
          (u.technicalDescription &&
            u.technicalDescription.search(
              new RegExp(escapeRegExp(searchTerm), "i")
            ) > -1) ||
          (u.shortTechnicalLabel &&
            u.shortTechnicalLabel.search(
              new RegExp(escapeRegExp(searchTerm), "i")
            ) > -1)
        ) {
          numberOfMatches++;
        }
      });
      return numberOfMatches === searchTerms.length;
    });
  }, [users, isHideUnavailableUsers, searchQuery])

  useEffect(() => {
    setFilteredUsers(filterUsers());
  }, [filterUsers]);

  function handleSubmit() {
    setFilteredUsers(() => filterUsers());
  }

  function keyPressed(event: any) {
    if (event.key === "Enter") {
      handleSubmit();
    }
  }

  function handleSearchQueryChange(searchQuery: string) {
    setSearchQuery(searchQuery);
  }

  const columnNames = [
    "User Id",
    "Full Name",
    "Technical Description",
    "Login/ Email Address",
    "Country",
    "Hours Available per Week",
    "Hourly Rate In USD",
    "Short Technical Label (frontend, backend, ...)",
    "Admin",
    "Last Active",
  ];

  return (
    <div>
      <Typography variant="h4" gutterBottom component="h2">
        Users
      </Typography>
      <>
        <div style={{ display: "inline-block" }}>
          <Input
            placeholder="languages, frameworks..."
            onKeyPress={keyPressed}
            style={{ width: "550px" }}
            onChange={(e) => handleSearchQueryChange(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSubmit()}
            style={{ marginLeft: "5px", marginRight: "20px" }}
          >
            Search
          </Button>
        </div>
        <Button
          onClick={() => props.toggleHideUnavailableUsersForAdmin()}
          variant="contained"
          color="primary"
          style={{ display: "inline-block" }}
        >
          {props.isHideUnavailableUsers ? "Show" : "Hide"} Unavailable Users
        </Button>
      </>
      <div className={classes.tableContainer}>
        <Paper elevation={2} className={classes.root}>
          <Table className={classes.table}>
            <ListHeader columns={columnNames} />
            <TableBody>
              {((filteredUsers || []) as User[]).map((user) => {
                return (
                  <TableRow
                    style={{ cursor: "pointer" }}
                    key={user.id}
                    onClick={() => navigateToAdminUsersViewPage(user.id)}
                  >
                    <TableCell component="th" scope="row">
                      {user.id}
                    </TableCell>
                    <TableCell>
                      {user.firstName} {user.lastName}
                    </TableCell>
                    <TableCell>{user.technicalDescription}</TableCell>
                    <TableCell>{user.emailAddress}</TableCell>
                    <TableCell>{user.country}</TableCell>
                    <TableCell>{user.numberOfHoursPerWeekAvailable}</TableCell>
                    <TableCell>{user.hourlyRateInUsd}</TableCell>
                    <TableCell>{user.shortTechnicalLabel}</TableCell>
                    <TableCell>{user.isAdmin ? "true" : "false"}</TableCell>
                    <TableCell>{formatShortDate(user.lastActiveAt)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </div>
    </div>
  );
}

const mapStateToProps = (storeState: StoreState) => ({
  isHideUnavailableUsers: storeState.admin.isHideUnavailableUsers,
});

export default connect(mapStateToProps, { toggleHideUnavailableUsersForAdmin })(
  withStyles(styles)(UsersTable)
);
