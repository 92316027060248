import React, { useState } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import { StoreState } from "../../redux/configure-store";
import ListHeader from "../../common/components/list-header";
import { formatShortDate } from "../../common/utils";
import { markInvoiceAsPaid } from "../admin-actions";
import { Invoice, PaymentMethod } from "../types";
import { stringSortCompare } from "../../common/utils";
import { routePaths, setPathId } from "../../routing/route-paths";

const styles: any = {
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
    marginBottom: "40px",
  },
  tableContainer: {
    height: 320,
  },
};

type PaymentMethodStats = {
  paymentMethod: PaymentMethod;
  totalAmountOwed: number;
  numberOfOpenInvoices: number;
};

function AdminInvoicesTable(props: any) {
  const { classes, markInvoiceAsPaid } = props;

  const [invoices, setInvoices] = useState([]);
  const [sortColumnName, setSortColumnName] = useState("Payment Due At");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

  const defaultPaymentMethodStats: PaymentMethodStats = {
    paymentMethod: "wise",
    totalAmountOwed: 0,
    numberOfOpenInvoices: 0,
  };
  const [numberOfOpenInvoices, setNumberOfOpenInvoices] = useState(0);
  const [totalAmountOwed, setTotalAmountOwed] = useState(0);
  const [bmoUsdStats, setBmoUsdStats] = useState<PaymentMethodStats>(
    defaultPaymentMethodStats
  );
  const [bitcoinStats, setBitcoinStats] = useState<PaymentMethodStats>(
    defaultPaymentMethodStats
  );
  const [wiseStats, setWiseStats] = useState<PaymentMethodStats>(
    defaultPaymentMethodStats
  );
  const [stablecoinStats, setStablecoinStats] = useState<PaymentMethodStats>(
    defaultPaymentMethodStats
  );

  function filterOpenInvoices(invoice: Invoice) {
    return !invoice.paidAt;
  }

  function filterPaymentMethod(paymentMethod: PaymentMethod) {
    return function (invoice: Invoice) {
      return invoice.user.paymentMethod === paymentMethod;
    };
  }

  function reduceInvoicePaymentAmount(acc: number, invoice: Invoice) {
    return invoice.totalPaymentAmount + acc;
  }

  React.useEffect(() => {
    setInvoices(props.invoices);
  }, [props.invoices]);

  React.useEffect(() => {
    if (!invoices || !invoices.length) {
      return;
    }
    const openInvoices = invoices.filter(filterOpenInvoices);
    setNumberOfOpenInvoices(openInvoices.length);

    setTotalAmountOwed(openInvoices.reduce(reduceInvoicePaymentAmount, 0));

    // open invoices by payment amount
    setBmoUsdStats({
      paymentMethod: "bmoUsd",
      totalAmountOwed: openInvoices
        .filter(filterPaymentMethod("bmoUsd"))
        .reduce(reduceInvoicePaymentAmount, 0),
      numberOfOpenInvoices: openInvoices.filter(filterPaymentMethod("bmoUsd"))
        .length,
    });

    setWiseStats({
      paymentMethod: "wise",
      totalAmountOwed: openInvoices
        .filter(filterPaymentMethod("wise"))
        .reduce(reduceInvoicePaymentAmount, 0),
      numberOfOpenInvoices: openInvoices.filter(filterPaymentMethod("wise"))
        .length,
    });

    setBitcoinStats({
      paymentMethod: "bitcoin",
      totalAmountOwed: openInvoices
        .filter(filterPaymentMethod("bitcoin"))
        .reduce(reduceInvoicePaymentAmount, 0),
      numberOfOpenInvoices: openInvoices.filter(filterPaymentMethod("bitcoin"))
        .length,
    });

    setStablecoinStats({
      paymentMethod: "stablecoin",
      totalAmountOwed: openInvoices
        .filter(filterPaymentMethod("stablecoin"))
        .reduce(reduceInvoicePaymentAmount, 0),
      numberOfOpenInvoices: openInvoices.filter(
        filterPaymentMethod("stablecoin")
      ).length,
    });
  }, [invoices]);

  const columnNames = [
    "id",
    { name: "Developer Name", isSortable: true },
    { name: "Client Name", isSortable: true },
    "Total Number of Hours",
    "Total Payment Amount",
    "Currency",
    "Period Start Date",
    "Period End Date",
    { name: "Created At", isSortable: true },
    "Payment Method",
    { name: "Payment Due At", isSortable: true },
    { name: "Paid At", isSortable: true },
  ];

  function onRowClick(publicId: string) {
    window.open(
      setPathId(routePaths.invoices.invoicesView, publicId),
      "_blank"
    );
  }

  const changeSort = (columnName: string) => {
    let newSortDirection: "asc" | "desc" = "desc";
    if (columnName === sortColumnName) {
      newSortDirection = sortDirection === "desc" ? "asc" : "desc";
    }
    setSortDirection(newSortDirection);
    setSortColumnName(columnName);
    let sortedInvoices;
    switch (columnName) {
      case "Developer Name":
        sortedInvoices = [...invoices].sort((a: any, b: any) => {
          if (newSortDirection === "asc") {
            return stringSortCompare(a.user.firstName, b.user.firstName);
          }
          return stringSortCompare(b.user.firstName, a.user.firstName);
        });
        setInvoices(sortedInvoices);
        break;
      case "Client Name":
        sortedInvoices = [...invoices].sort((a: any, b: any) => {
          if (newSortDirection === "asc") {
            return stringSortCompare(
              a.engagement.clientName,
              b.engagement.clientName
            );
          }
          return stringSortCompare(
            b.engagement.clientName,
            a.engagement.clientName
          );
        });
        setInvoices(sortedInvoices);
        break;
      case "Payment Due At":
        sortedInvoices = [...invoices].sort((a: any, b: any) => {
          if (newSortDirection === "asc") {
            return (
              new Date(a.paymentDueAt).getTime() -
              new Date(b.paymentDueAt).getTime()
            );
          }
          return (
            new Date(b.paymentDueAt).getTime() -
            new Date(a.paymentDueAt).getTime()
          );
        });
        setInvoices(sortedInvoices);
        break;
      case "Created At":
        sortedInvoices = [...invoices].sort((a: any, b: any) => {
          if (newSortDirection === "asc") {
            return (
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
            );
          }
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        });
        setInvoices(sortedInvoices);
        break;
      case "Paid At":
        sortedInvoices = [...invoices].sort((a: any, b: any) => {
          if (newSortDirection === "asc") {
            return new Date(a.paidAt).getTime() - new Date(b.paidAt).getTime();
          }
          return new Date(b.paidAt).getTime() - new Date(a.paidAt).getTime();
        });
        setInvoices(sortedInvoices);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <Grid container spacing={4} style={{ marginBottom: "20px" }}>
        <Grid item sm={6}>
          <Typography variant="h4" gutterBottom component="h2">
            Invoices
          </Typography>
        </Grid>
        <Grid item sm={6}>
          <Typography variant="body1" gutterBottom component="h4">
            Total Amount Owed: {totalAmountOwed} --- Total # Of Open Invoices:{" "}
            {numberOfOpenInvoices}
          </Typography>
          <Typography variant="body1" gutterBottom component="h4">
            Owed in BMO USD: {bmoUsdStats.totalAmountOwed} --- No. of Open
            Invoinces {bmoUsdStats.numberOfOpenInvoices}
          </Typography>
          <Typography variant="body1" gutterBottom component="h4">
            Owed in Bitcoin: {bitcoinStats.totalAmountOwed} --- No. of Open
            Invoices {bitcoinStats.numberOfOpenInvoices}
          </Typography>
          <Typography variant="body1" gutterBottom component="h4">
            Owed in Stablecoin: {stablecoinStats.totalAmountOwed} --- No. of
            Open Invoices {stablecoinStats.numberOfOpenInvoices}
          </Typography>
          <Typography variant="body1" gutterBottom component="h4">
            Owed in Wise: {wiseStats.totalAmountOwed} --- No. of Open Invoices{" "}
            {wiseStats.numberOfOpenInvoices}
          </Typography>
        </Grid>
      </Grid>
      <div className={classes.tableContainer}>
        <Paper elevation={2} className={classes.root}>
          <Table className={classes.table}>
            <ListHeader
              columns={columnNames}
              sortDirection={sortDirection}
              sortColumnName={sortColumnName}
              onColumnClick={(columnName: string) => changeSort(columnName)}
            />
            <TableBody>
              {((invoices || []) as Invoice[]).map((invoice, index) => {
                const background = index % 2 === 0 ? "#eee" : "white";
                return (
                  <TableRow
                    style={{ cursor: "pointer", background }}
                    key={invoice.publicId}
                    onClick={() => onRowClick(invoice.publicId)}
                  >
                    <TableCell component="th" scope="row">
                      {invoice.publicId}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {invoice.user.firstName} {invoice.user.lastName}
                    </TableCell>
                    <TableCell>{invoice.engagement.clientName}</TableCell>
                    <TableCell>{invoice.totalNumberOfHours}</TableCell>
                    <TableCell>{invoice.totalPaymentAmount}</TableCell>
                    <TableCell>{invoice.currency}</TableCell>

                    <TableCell>
                      {formatShortDate(invoice.periodStartDate)}
                    </TableCell>
                    <TableCell>
                      {formatShortDate(invoice.periodEndDate)}
                    </TableCell>

                    <TableCell>{formatShortDate(invoice.createdAt)}</TableCell>
                    <TableCell>{invoice.user.paymentMethod}</TableCell>
                    <TableCell>
                      {formatShortDate(invoice.paymentDueAt)}
                    </TableCell>
                    <TableCell>
                      {invoice.paidAt ? (
                        formatShortDate(invoice.paidAt)
                      ) : (
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            markInvoiceAsPaid(invoice.publicId);
                          }}
                          style={{ width: "auto" }}
                        >
                          Mark as Paid
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </div>
    </div>
  );
}

const mapStateToProps = (storeState: StoreState) => ({
  invoices: storeState.admin.invoices,
});

export default connect(mapStateToProps, { markInvoiceAsPaid })(
  withStyles(styles)(AdminInvoicesTable)
);
