import { ReduxAction } from "../common/interfaces";

import { LOGOUT_SUCCESS } from "../authentication/authentication-actions";
import {
  FETCH_ENGAGEMENTS_SUCCESS,
  FETCH_INVOICES_SUCCESS,
  SHOW_SUCCESS_SNACKBAR,
  SWITCH_MENU_DRAWER,
} from "./user-actions";

interface UserState {
  isMenuDrawerOpen: boolean;
  languageCode: string;
  userSettings: any;
  isUpdatingLogoImage: boolean;
  isUpdatingProfileImage: boolean;
  isUpdatingAutoDialerSettings: boolean;
  isShowSuccessSnackbar: boolean,
  engagements: any[];
  invoices: any[];
}

const initialState: UserState = {
  isMenuDrawerOpen: false,
  languageCode: "en",
  userSettings: {},
  isUpdatingLogoImage: false,
  isUpdatingProfileImage: false,
  isUpdatingAutoDialerSettings: false,
  isShowSuccessSnackbar: false,
  engagements: [],
  invoices: [],
};

export const userReducer = (
  state: UserState = initialState,
  action: ReduxAction
): UserState => {
  const { payload, type } = action;
  switch (type) {
    case FETCH_ENGAGEMENTS_SUCCESS:
      return {
        ...state,
        engagements: payload || [],
      };
    case FETCH_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: payload || [],
      };
    case SWITCH_MENU_DRAWER:
      return {
        ...state,
        isMenuDrawerOpen: !state.isMenuDrawerOpen,
      };
    case LOGOUT_SUCCESS:
      return initialState;
    case SHOW_SUCCESS_SNACKBAR:
      return {
        ...state,
        isShowSuccessSnackbar: payload.isOpen,
      };
    default:
      return state;
  }
};
