import React, { useEffect, useState } from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";

import { history, StoreState } from "./redux/configure-store";
import AdminPage from "./admin/AdminPage";
import AppRoutes from "./routing/app-routes";
import LoginPage from "./authentication/login-page";
import NoMatch from "./routing/no-match";
import theme from "./theme/theme";
import { routePaths } from "./routing/route-paths";
import { initializeHttpService } from "./http-service";
import PrivateRoute from "./routing/private-route";
import UnauthenticatedRoute from "./routing/unauthenticated-route";
import { validateSessionCookie } from "./authentication/authentication-actions";

import "./App.css";
import InvoicesView from "./invoices/invoices-view";

interface AppProps {
  validateSessionCookie: Function;
  validatingSessionCookie: boolean;
  isAuthenticated: boolean;
  isAdmin: boolean;
  initializeHttpService: Function;
  match: any;
}

const renderApp = (isAuthenticated: boolean, isAdmin: boolean) => {
  return (
    <ConnectedRouter history={history}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Switch>
          <UnauthenticatedRoute
            path={routePaths.auth.login}
            component={LoginPage}
            redirectPath={routePaths.dashboard.root}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path={routePaths.admin.root}
            component={AdminPage}
            redirectPath={routePaths.auth.login}
            isAuthenticated={isAuthenticated}
            authorized={isAuthenticated && isAdmin}
          />
          <PrivateRoute
            path={routePaths.invoices.invoicesView}
            component={InvoicesView}
            redirectPath={routePaths.auth.login}
            isAuthenticated={isAuthenticated}
            authorized={isAuthenticated}
          />
          <PrivateRoute
            path={routePaths.root}
            component={AppRoutes}
            redirectPath={routePaths.auth.login}
            isAuthenticated={isAuthenticated}
            authorized={isAuthenticated}
          />
          <Route component={NoMatch} />
        </Switch>
      </MuiThemeProvider>
    </ConnectedRouter>
  );
};

function App(props: AppProps) {

  const {
    isAuthenticated,
    isAdmin,
    validatingSessionCookie,
    validateSessionCookie: _validateSessionCookie,
    initializeHttpService: _initializeHttpService
  } = props;
  const [isMounting, setIsMounting] = useState(true);

  useEffect(() => {
    _validateSessionCookie()
      .then(_initializeHttpService)
      .finally(() => {
        setIsMounting(false);
      })
  }, [_validateSessionCookie, _initializeHttpService]);

  return (
    <div>
      {isMounting || validatingSessionCookie
        ? "Loading..."
        : renderApp(isAuthenticated, isAdmin)}
    </div>
  );
}

const mapStateToProps = (storeState: StoreState) => {
  const authenticationState = storeState.authentication;
  const { user, isAuthenticated } = authenticationState;
  return {
    isAuthenticated,
    isAdmin: user && user.isAdmin,
    validatingSessionCookie: storeState.authentication.validatingSessionCookie,
  };
};

export default connect(mapStateToProps, {
  initializeHttpService,
  validateSessionCookie,
})(App as any);
