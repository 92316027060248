import React, { ChangeEvent, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { createStyles, Paper, Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Grid from "@material-ui/core/Grid";

import dayjs from "../date";
import { Invoice } from "../../admin/types";
import ListHeader from "./list-header";
import { routePaths, setPathId } from "../../routing/route-paths";

const formatDateForInvoice = (dateStr: string) => {
  return dayjs(dateStr).utc().format("YYYY-MM-DD");
};

const formatCurrencyAmount = (amount: number, currency: string): string => {
  return new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: currency || "USD",
  }).format(amount);
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
    },
    primaryLabel: {
      fontSize: theme.typography.h6.fontSize,
    },
    textGrey: {
      color: theme.palette.grey[800],
    },
  });

const InvoicesList = (props: any) => {

  const { classes, invoices } = props;
  const columnNames = [
    "Invoice Id",
    "Created Date",
    "Payment",
    "Status",
    "",
  ];

  const NUMBER_OF_INVOICES_PER_PAGE = 10;
  const [page, setPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1)

  useEffect(() => {
    if (!invoices || !invoices.length) {
      setNumberOfPages(1);
      return;
    }
    setNumberOfPages(Math.ceil(invoices.length / NUMBER_OF_INVOICES_PER_PAGE))
  }, [invoices])

  function handlePaginationChange(event: ChangeEvent<unknown>, value: number) {
    setPage(value)
  }

  const displayedInvoices = (invoices || [])
    .slice((page - 1) * NUMBER_OF_INVOICES_PER_PAGE, page * NUMBER_OF_INVOICES_PER_PAGE) as Invoice[];

  return (
    <Paper elevation={2} className={classes.root}>
      <Typography className={classes.primaryLabel}>Invoices</Typography>
      <div>
        {invoices && invoices.length ? (
          <>
            <Table className={classes.table}>
              <ListHeader columns={columnNames} />
              <TableBody>
                {displayedInvoices.map((invoice) => {
                  return (
                    <TableRow
                      style={{ cursor: "pointer" }}
                      key={invoice.publicId}
                    >
                      <TableCell component="th" scope="row">
                        {invoice.publicId}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {formatDateForInvoice(invoice.createdAt)}
                      </TableCell>
                      <TableCell>
                        {formatCurrencyAmount(
                          invoice.totalPaymentAmount,
                          invoice.currency
                        )}
                      </TableCell>
                      <TableCell>
                        {invoice.paidAt ? "Paid" : "Not Paid"}
                      </TableCell>
                      <TableCell>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={setPathId(routePaths.invoices.invoicesView, invoice.publicId)}
                        >
                          View
                        </a>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Grid container alignItems="center">
              <Grid>
              </Grid>
              <Grid style={{ marginLeft: "auto", paddingTop: "15px" }}>
                <Pagination
                  count={numberOfPages}
                  defaultPage={1}
                  page={page}
                  siblingCount={0}
                  size="small"
                  onChange={handlePaginationChange}
                />
              </Grid>
            </Grid>
            <div style={{ position: "absolute", right: 0, paddingTop: "15px" }}>

            </div>
          </>
        ) : null}
        {!invoices || !invoices.length ? (
          <Typography>You do not have any invoices yet.</Typography>
        ) : null}
      </div>
    </Paper >
  );
}

export default withStyles(styles, { withTheme: true })(InvoicesList);
