import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import "./styles.css";

import { StoreState } from "../redux/configure-store";
import { pathToLogoComplete } from "../common/assets";
import { Invoice } from "../admin/types";
import { parseRouteIdFromProps } from "../routing/route-paths";
import api from "../api/api";

import dayjs from "../common/date";
import { formatCurrencyAmount } from "../common/utils";
import Loading from "../common/components/Loading";

const styles = (theme: any) => ({});

const formatDateForInvoice = (dateStr: string) => {
  return dayjs(dateStr).utc().format("YYYY-MM-DD");
};

class InvoicesView extends React.Component<any> {
  state = {
    invoice: null,
  };

  componentDidMount() {
    const publicId = parseRouteIdFromProps(this.props, false);
    api.users
      .fetchInvoice(publicId as string)
      .then((_invoice: Invoice) => {
        this.setState({
          invoice: _invoice,
        });
      })
      .catch(() => {
        //
      });
  }

  render() {
    const invoice = this.state.invoice as unknown as Invoice;
    if (!invoice) {
      return <Loading></Loading>
    }
    document.title = `${invoice.publicId} - DoneStreet`;
    return (
      <div className="invoice-box">
        <table cellPadding={0} cellSpacing={0}>
          <tr className="top">
            <td colSpan={2}>
              <table>
                <tr>
                  <td className="title">
                    <img
                      src={pathToLogoComplete}
                      alt="DoneStreet Logo"
                      style={{ width: "100%", maxWidth: "100px" }}
                    />
                  </td>
                  <td>
                    Invoice #: {invoice.publicId}
                    <br />
                    Created: {formatDateForInvoice(invoice.createdAt)}
                    <br />
                    Due: {formatDateForInvoice(invoice.paymentDueAt)}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          {invoice.paidAt ? (
            <tr>
              <td
                style={{
                  color: "green",
                  fontSize: "30px",
                  paddingLeft: "100px",
                  textAlign: "left",
                }}
              >
                Paid
              </td>
            </tr>
          ) : null}
          <tr className="information">
            <td colSpan={2}>
              <table>
                <tr>
                  <td>
                    <b>Bill To:</b>
                    <br />
                    DoneStreet Inc.
                    <br />
                    412 - 1288 Ritson Road North
                    <br />
                    Oshawa ON L1G 7Z9
                    <br />
                    Canada
                    <br />
                  </td>

                  <td>
                    {invoice.user.firstName} {invoice.user.lastName}
                    <br />
                    {invoice.user.emailAddress}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr className="heading">
            <td>Payment Method</td>
            <td></td>
          </tr>
          <tr className="details">
            <td>Online Transfer</td>
            <td></td>
          </tr>
          <tr className="heading">
            <td>Item</td>
            <td>Price</td>
          </tr>

          <tr className="item last">
            <td>
              {invoice.totalNumberOfHours}hrs of Software Development services
              from {formatDateForInvoice(invoice.periodStartDate)} to{" "}
              {formatDateForInvoice(invoice.periodEndDate)} for{" "}
              {invoice.engagement.clientName}
            </td>
            <td>
              {formatCurrencyAmount(
                invoice.totalPaymentAmount -
                invoice.secondaryItemAmount -
                invoice.salesTaxAmount,
                invoice.currency
              )}
            </td>
          </tr>
          {invoice.secondaryItemAmount && invoice.secondaryItemDescription ? (
            <tr className="item last">
              <td>{invoice.secondaryItemDescription}</td>
              <td>
                {formatCurrencyAmount(
                  invoice.secondaryItemAmount,
                  invoice.currency
                )}
              </td>
            </tr>
          ) : null}

          <tr className="total">
            <td></td>
            <td>Currency: {invoice.currency}</td>
          </tr>
          <tr className="total">
            <td></td>
            <td>
              Sales Tax:{" "}
              {formatCurrencyAmount(invoice.salesTaxAmount, invoice.currency)}
            </td>
          </tr>
          <tr className="total">
            <td></td>
            <td>
              Total:{" "}
              {formatCurrencyAmount(
                invoice.totalPaymentAmount,
                invoice.currency
              )}
            </td>
          </tr>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (storeState: StoreState) => ({});

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles as any)(InvoicesView as any));
