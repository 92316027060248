import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Theme, createStyles, withStyles } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

import { colors } from "../../theme/colors";
import { isIE } from "../utils";

const styles = (theme: Theme) =>
  createStyles({
    tableHeader: {
      zIndex: 999,
      position: "sticky",
      top: 0,
      textAlign: "left",
      color: colors.black,
      fontWeight: 600,
    },
    tableHeaderIE: {
      fontWeight: 600,
      textAlign: "left",
      color: colors.black,
    },
  });

type Column = string | { name: string; isSortable?: boolean };

interface ListHeaderProps {
  columns: Column[];
  classes: any;
  onColumnClick?: (columnKey: string) => void;
  sortDirection?: "asc" | "desc" | undefined;
  sortColumnName?: string;
}

const ListHeader = (props: ListHeaderProps) => {
  const { classes, columns, onColumnClick, sortDirection, sortColumnName } =
    props;
  return (
    <TableHead>
      <TableRow>
        {columns.map((column: Column) => {
          const columnName = typeof column === "string" ? column : column.name;
          const isSortable =
            typeof column === "string" ? false : column.isSortable;
          return (
            <TableCell
              key={columnName}
              className={isIE() ? classes.tableHeaderIE : classes.tableHeader}
              onClick={() => {
                if (onColumnClick && isSortable) {
                  onColumnClick(columnName);
                }
              }}
              style={isSortable ? { cursor: "pointer" } : {}}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <span>{columnName}</span>
                {sortColumnName === columnName && sortDirection ? (
                  <div style={{ display: "inline-block" }}>
                    {sortDirection === "asc" ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                  </div>
                ) : null}
              </div>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default withStyles(styles)(ListHeader);
