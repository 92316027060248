import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";

import dayjs from "./date";

import { Timesheet } from "../admin/types";

momentDurationFormatSetup(moment as any);

export const passwordRequirements = "Minimum 6 characters";

export const isValidPassword = (password: string) =>
  password && password.length >= 6;

export const formatCurrencyAmount = (
  amount: number,
  currency = "USD"
): string => {
  return new Intl.NumberFormat(undefined, {
    style: "currency",
    currency,
  }).format(amount);
};

export const stringSortCompare = (a: string, b: string) => {
  const A = a.toUpperCase();
  const B = b.toUpperCase();
  if (A < B) {
    return -1;
  }
  if (A > B) {
    return 1;
  }

  return 0;
};

export const getInitials = (name: string): string => {
  const names = name.split(" ");
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const formatShortDate = (dateStr: string): string | null => {
  if (!dateStr) {
    return null;
  }
  return dayjs(dateStr).utc().format("YYYY-MM-DD");
};

export const isDefined = (variable: any) =>
  typeof variable === "undefined" ? false : true;

export const createRandomString = (
  length: number = 10,
  characters = "abcdefghjkmnpqrstuvwxyz23456789"
): string => {
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

declare global {
  interface Document {
    documentMode?: any;
  }
}

export const roundTwoDecimals = (num: number): number => {
  return Math.round(num * 100 + Number.EPSILON) / 100;
};

export const isIE = (): boolean => !!document.documentMode;

/**
 * Returns times in decimal or minutes format
 */
export const calculateTotalHoursForTimesheets = (timesheets: Timesheet[]): string => {
  const timesheetDetails = timesheets.reduce((acc: any[], timesheet: Timesheet) => {
    return [...acc, ...timesheet.details];
  }, []);
  return calculateTotalNumberOfHoursForTimesheet(timesheetDetails, timesheets[0].timeFormat);
};

export const isValidTimesheetTimeFormat = (
  time: string,
  format: "decimals" | "minutes"
): boolean => {
  if (format === "minutes") {
    return !!time.match(/^[0-9]{1,2}((:|\.)[0-9]{2})?$/);
  }
  return !!time.match(/^[0-9]{1,2}(\.[0-9]{1,2})?$/);
};

/**
 * removes the time from a date string, this fixes some Timezone issues in the UI
 */
export const formatTimesheetDate = (date: string) => {
  return dayjs(date.split("T")[0]).format(
    "ddd MMMM D, YYYY"
  );
}

/**
 * guillermo.castillo-29
 */
export const generateInvoiceId = (previousInvoiceId: string) => {
  // The following regex allows for accented letters, such as "josé.invó"
  if (!previousInvoiceId.match(/^[a-z\u00E0-\u00FC]+[.][a-z\u00E0-\u00FC]+[-]\d+$/)) {
    return "";
  }
  const [firstName, lastNameAndInvoiceNumber] = previousInvoiceId.split(".")
  const [lastName, invoiceNumber] = lastNameAndInvoiceNumber.split("-")
  const nextInvoiceNumber = parseInt(invoiceNumber) + 1;
  return `${firstName}.${lastName}-${nextInvoiceNumber < 10 ? '0' + nextInvoiceNumber : nextInvoiceNumber}`
};

/**
 * Returns the total number of hours in:
 * - decimal format (eg. 1.5 hours is one and a half hours) or
 * - time format (eg. 1:30 is one and a half hours)
 */
export const calculateTotalNumberOfHoursForTimesheet = (
  details: Timesheet["details"],
  timesheetTimeFormat: Timesheet["timeFormat"],
): string => {
  if (timesheetTimeFormat === "decimals") {
    const numberOfHours = details.reduce((prev: number, curr: any) => {
      if (
        !curr.numberOfHours ||
        !isValidTimesheetTimeFormat(curr.numberOfHours, "decimals")
      ) {
        return prev;
      }
      return prev + parseFloat(curr.numberOfHours);
    }, 0);

    return String(roundTwoDecimals(numberOfHours));
  } else if (timesheetTimeFormat === "minutes") {
    const numberOfHours = details.reduce((prev: number, curr: any) => {
      const amountOfTime = curr.numberOfHours;
      if (
        !amountOfTime ||
        !isValidTimesheetTimeFormat(amountOfTime, "minutes")
      ) {
        return prev;
      }
      let hours = 0;
      if (amountOfTime.indexOf(":") > -1) {
        hours = moment.duration(amountOfTime).asHours();
      } else if (amountOfTime.indexOf(".") > -1) {
        hours = moment.duration(amountOfTime.replace(".", ":")).asHours();
      } else {
        hours = parseInt(amountOfTime);
      }
      return prev + hours;
    }, 0);

    return moment.duration(numberOfHours, "hours").format("hh:mm");
  }

  throw new Error("Invalid timesheet timeFormat");
};

export function transformMinutesTimeFormatToDecimals(timeInMinutesFormat: string): number {
  const hoursInDecimal = moment.duration(timeInMinutesFormat).asHours();
  return Math.round( ( hoursInDecimal + Number.EPSILON ) * 100 ) / 100;
}
