import {
  FETCH_APP_STATES_SUCCESS,
  ADMIN_FETCH_TIMESHEETS_SUCCESS,
  ADMIN_FETCH_ENGAGEMENTS_SUCCESS,
  ADMIN_FETCH_INVOICES_SUCCESS,
  ADMIN_FETCH_USERS_SUCCESS,
  ADMIN_EDIT_USER_START,
  ADMIN_EDIT_USER_SUCCESS,
  ADMIN_EDIT_USER_FAIL,
  ADMIN_TOGGLE_HIDE_UNAVAILABLE_USERS,
  ADMIN_UNSET_SELECTED_TIMESHEETS,
  ADMIN_SET_SELECTED_TIMESHEETS,
} from "./admin-actions";

import { LOGOUT_SUCCESS } from "../authentication/authentication-actions";
import { ReduxAction } from "../common/interfaces";
import { Invoice, User, Timesheet } from "./types";

interface AdminState {
  appStates: any[];
  invoices: Invoice[];
  users: User[];
  timesheets: Timesheet[];
  engagements: any[];
  selectedTimesheetIds: number[];
  isEditingUser: boolean;
  isHideUnavailableUsers: boolean;
  errorEditingUser: string;
}

const createInitialState = (): AdminState => ({
  appStates: [],
  engagements: [],
  invoices: [],
  timesheets: [],
  users: [],
  selectedTimesheetIds: [],
  isHideUnavailableUsers: false,
  isEditingUser: false,
  errorEditingUser: "",
});

export const adminReducer = (
  state: AdminState = createInitialState(),
  action: ReduxAction
): AdminState => {
  const { payload, type } = action;
  switch (type) {
    case ADMIN_TOGGLE_HIDE_UNAVAILABLE_USERS:
      return {
        ...state,
        isHideUnavailableUsers: !state.isHideUnavailableUsers,
      };
    case FETCH_APP_STATES_SUCCESS:
      return {
        ...state,
        appStates: payload.appStates,
      };
    case ADMIN_FETCH_ENGAGEMENTS_SUCCESS:
      return {
        ...state,
        engagements: payload.engagements,
      };
    case ADMIN_FETCH_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: payload.invoices,
      };
    case ADMIN_FETCH_TIMESHEETS_SUCCESS:
      return {
        ...state,
        timesheets: payload.timesheets,
      };
    case ADMIN_FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: payload.users,
      };
    case ADMIN_EDIT_USER_START:
      return {
        ...state,
        isEditingUser: true,
        errorEditingUser: "",
      };
    case ADMIN_EDIT_USER_SUCCESS:
      return {
        ...state,
        isEditingUser: false,
        errorEditingUser: "",
      };
    case ADMIN_EDIT_USER_FAIL:
      return {
        ...state,
        isEditingUser: false,
        errorEditingUser: "Error editing user",
      };
    case ADMIN_SET_SELECTED_TIMESHEETS:
      return {
        ...state,
        selectedTimesheetIds: payload.timesheetIds,
      };
    case ADMIN_UNSET_SELECTED_TIMESHEETS:
      return {
        ...state,
        selectedTimesheetIds: [],
      };
    case LOGOUT_SUCCESS:
      return createInitialState();
    default:
      return state;
  }
};
