export const routePaths = {
  admin: {
    root: "/admin",
    appStates: "/admin/app-states",
    engagements: "/admin/engagements",
    engagementsView: "/admin/engagements/:id",
    engagementsCreateInvoice: "/admin/engagements/:id/invoices",
    invoices: "/admin/invoices",
    kpis: "/admin/kpis",
    register: "/admin/register",
    timesheets: "/admin/timesheets",
    timesheetsView: "/admin/timesheets/:id",
    users: "/admin/users",
    usersView: "/admin/users/:id",
    usersCreateEngagement: "/admin/users/:id/engagements",
  },
  auth: {
    changePassword: "/change-password",
    login: "/login",
  },
  dashboard: {
    root: "/dashboard",
  },
  engagements: {
    engagementsView: "/engagements/:id",
  },
  invoices: {
    invoicesView: "/invoices/:id",
  },
  root: "/",
  user: {
    root: "/",
    settings: "/settings",
  },
};

export const parseRouteIdFromProps = (
  props: any,
  isNumber = true
): number | string => {
  if (isNumber) {
    return parseInt(props.match.params.id);
  }
  return String(props.match.params.id);
};

export const setPathId = (path: string, id: number | string): string =>
  path.replace(":id", String(id));
