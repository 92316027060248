import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

import { StoreState } from "../redux/configure-store";
import DashboardEarningsStats from "./DashboardEarningsStats";
import DashboardEngagements from "./DashboardEngagements";
import InvoicesList from "../common/components/InvoicesList";
import { fetchEngagements, fetchInvoices } from "../user/user-actions";
import { navigateToEngagementsViewPage } from "../routing/route-actions";
import Snackbar from "../common/components/snackbar"

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
      width: "100%",
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    primaryLabel: {
      textAlign: "left",
      fontSize: theme.typography.h6.fontSize,
      textTransform: "uppercase",
      paddingBottom: "10px",
    },
  });

function DashboardPage(props: any) {

  const { classes, engagements, invoices, user, isShowSuccessSnackbar, fetchEngagements: _fetchEngagements, fetchInvoices: _fetchInvoices } = props;

  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] = useState(false);

  const handleSuccessSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSuccessSnackbarOpen(false);
  };

  useEffect(() => {
    setIsSuccessSnackbarOpen(isShowSuccessSnackbar)
  }, [isShowSuccessSnackbar])

  useEffect(() => {
    _fetchEngagements();
    _fetchInvoices();
  }, [_fetchInvoices, _fetchEngagements])

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={3}
        style={{ maxWidth: "1400px", margin: "auto" }}
      >
        <Grid item md={6} style={{ minWidth: "600px" }}>
          <DashboardEarningsStats
            engagements={engagements}
            invoices={invoices}
            user={user}
          />
          <div style={{ marginTop: "10px" }}>
            <InvoicesList invoices={invoices} />
          </div>
        </Grid>
        <Grid item md={6} style={{ minWidth: "600px" }}>
          <DashboardEngagements
            engagements={engagements}
            onEngagementClick={(publicId: string) =>
              props.navigateToEngagementsViewPage(publicId)
            }
          />
        </Grid>
      </Grid>
      <Snackbar severity="success" handleClose={handleSuccessSnackbarClose} alertMessage="Success" isOpen={isSuccessSnackbarOpen}></Snackbar>
    </div>
  );
}

const mapStateToProps = (storeState: StoreState) => ({
  engagements: storeState.user.engagements,
  invoices: storeState.user.invoices,
  user: storeState.authentication.user,
  isShowSuccessSnackbar: storeState.user.isShowSuccessSnackbar,
});

export default connect(mapStateToProps, {
  fetchEngagements,
  fetchInvoices,
  navigateToEngagementsViewPage,
})(withStyles(styles)(DashboardPage)) as any;
