import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormLabel";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";

import { StoreState } from "../redux/configure-store";
import Engagement from "./Engagement";
import {
  calculateTotalNumberOfHoursForTimesheet,
  isValidTimesheetTimeFormat,
  formatTimesheetDate,
} from "../common/utils";
import {
  fetchEngagements,
  fetchInvoices,
  saveTimesheetDraft,
} from "../user/user-actions";
import InvoicesList from "../common/components/InvoicesList";
import Loading from "../common/components/Loading"

const styles = (theme: Theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    margin: "auto",
    maxWidth: "1400px",
  },
  paper: {
    padding: theme.spacing(3),
  },
  noSubmittedTimesheets: {
    display: "flex",
    alignItems: "flex-end",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  primaryLabel: {
    fontSize: theme.typography.h6.fontSize,
    textTransform: "uppercase",
  },
});

class Timesheets extends React.Component<any> {
  state = {
    isSnackbarOpen: false,
    isInitialized: false,
    details: [],
    notes: "",
    activeTimesheetId: null,
    timesheetStartDate: new Date().toISOString(),
    timesheetEndDate: new Date().toISOString(),
    error: "",
    timeFormat: "decimals" as "decimals" | "minutes",
    totalNumberOfHours: 0,
  };

  changeNotes = (e: any) => {
    this.setState({
      notes: e.target.value,
    });
  };

  changeNumberOfHours = (date: string, numberOfHours: string) => {
    this.setState((prev: any) => {
      const details = prev.details.map((detail: any) => {
        if (detail.date !== date) {
          return detail;
        }
        return { date: detail.date, numberOfHours: numberOfHours.trim() };
      });
      return {
        ...prev,
        details,
        totalNumberOfHours: calculateTotalNumberOfHoursForTimesheet(
          details,
          prev.timeFormat,
        ),
      };
    });
  };

  saveDraftOrSubmit = (isSubmitting = false) => {
    let isError = false;
    let errorMessage =
      "Error saving timesheet. All values must be numbers or times.";
    this.state.details.forEach((detail: any) => {
      if (detail.numberOfHours === "") {
        isError = true;
        errorMessage =
          "Empty values are not allowed. Please enter an amount for each day.";
      } else if (detail.numberOfHours.indexOf("-") > -1) {
        isError = true;
        errorMessage = "Negative numbers are not allowed";
      } else if (
        !isValidTimesheetTimeFormat(detail.numberOfHours, this.state.timeFormat)
      ) {
        isError = true;
        errorMessage =
          this.state.timeFormat === "minutes"
            ? "All values must be in time format (eg. '1', '1.20', '1:35')"
            : "All values must be in decimal format (eg. '1', '1.1', '1.74')";
      }
    });
    if (isError) {
      this.setState({
        error: errorMessage,
      });
      return;
    }

    this.props.saveTimesheetDraft(
      this.state.activeTimesheetId,
      this.state.details,
      this.state.notes,
      this.state.timeFormat,
      isSubmitting
    );
  };

  isTimesheetDaysOffTrackingType(engagement: any) {
    return engagement.trackingType === "daysOff";
  }

  isTimesheetAvailable(): boolean {
    const { engagement } = this.props;
    return (
      engagement &&
      engagement.timesheets &&
      engagement.timesheets.length &&
      !engagement.timesheets[engagement.timesheets.length - 1].submittedAt
    );
  }

  initTimesheet() {
    const { engagement } = this.props;
    if (this.isTimesheetAvailable() && !this.state.isInitialized) {
      const activeTimesheet =
        engagement.timesheets[engagement.timesheets.length - 1];
      this.setState({
        isInitialized: true,
        activeTimesheetId: activeTimesheet.id,
        details: activeTimesheet.details,
        notes: activeTimesheet.notes,
        timesheetStartDate: activeTimesheet.startDate,
        timesheetEndDate: activeTimesheet.endDate,
        timeFormat: activeTimesheet.timeFormat,
        totalNumberOfHours: calculateTotalNumberOfHoursForTimesheet(
          activeTimesheet.details,
          activeTimesheet.timeFormat,
        ),
      });
    }
  }

  componentDidMount() {
    this.initTimesheet();
  }

  componentDidUpdate(oldProps: any) {
    if (!this.state.isInitialized) {
      this.initTimesheet();
    }
  }

  onChangeTimeFormat(e: any) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.setState((prev: any) => ({
      ...prev,
      totalNumberOfHours: calculateTotalNumberOfHoursForTimesheet(
        prev.details,
        prev.timeFormat,
      ),
    }));
  }

  render() {
    const { classes, engagement } = this.props;
    const isTimesheetAvailable = this.isTimesheetAvailable();
    return (
      <Paper className={classes.paper}>
        <Typography
          className={classes.primaryLabel}
          style={{ marginBottom: "10px" }}
        >
          Timesheet
        </Typography>
        {!isTimesheetAvailable && (
          <div className={classes.noSubmittedTimesheets}>
            <Typography>There are no timesheets available.</Typography>
          </div>
        )}
        {isTimesheetAvailable ? (
          <>
            {this.isTimesheetDaysOffTrackingType(engagement) ? (
              <>
                <span>
                  Please enter any days you took off during the period:
                </span>
                <br />
                <b>
                  {formatTimesheetDate(this.state.timesheetStartDate)}{" "}
                  to{" "}
                  {formatTimesheetDate(this.state.timesheetEndDate)}
                </b>
              </>
            ) : (
              <>
                <FormControl
                  margin="normal"
                  fullWidth
                  style={{ marginBottom: "20px", fontWeight: "bold" }}
                >
                  <FormLabel component="legend">Time Format</FormLabel>
                  <RadioGroup
                    aria-label="timeFormat"
                    name="timeFormat"
                    value={this.state.timeFormat}
                    onChange={(e) => this.onChangeTimeFormat(e)}
                  >
                    <FormControlLabel
                      value="decimals"
                      control={<Radio />}
                      label="Decimals (eg. '1.5' for one and a half hours)"
                    />
                    <FormControlLabel
                      value="minutes"
                      control={<Radio />}
                      label="Minutes (eg. '1:30' for one and a half hours)"
                    />
                  </RadioGroup>
                </FormControl>
                {this.state.details.map((detail: any, index: number) => (
                  <Grid key={index} container spacing={2}>
                    <Grid item xs={6}>
                      <div style={{ padding: "5px" }}>
                        {formatTimesheetDate(detail.date)}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        style={{ width: "50px" }}
                        inputProps={{ style: { textAlign: "center" } }}
                        onChange={(e) =>
                          this.changeNumberOfHours(detail.date, e.target.value)
                        }
                        value={detail.numberOfHours}
                      />
                    </Grid>
                  </Grid>
                ))}
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <div style={{ padding: "5px" }}>
                      <b>Total:</b>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ width: "50px", textAlign: "center" }}>
                      <b>{this.state.totalNumberOfHours}</b>
                    </div>
                  </Grid>
                </Grid>
              </>
            )}
            <FormControl margin="normal" fullWidth>
              <InputLabel htmlFor="notes">Notes</InputLabel>
              <Input
                name="notes"
                type="text"
                id="notes"
                autoComplete="off"
                multiline={true}
                rows={6}
                value={this.state.notes}
                onChange={this.changeNotes}
              />
            </FormControl>
            {this.state.error && (
              <FormHelperText style={{ color: "red" }}>
                {this.state.error}
              </FormHelperText>
            )}
            <Grid container spacing={2} style={{ marginTop: "10px" }}>
              <Grid item sm={6}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={() => this.saveDraftOrSubmit(false)}
                >
                  Save Draft
                </Button>
              </Grid>
              <Grid item sm={6}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to submit? The timesheet cannot be edited once it is submitted."
                      )
                    ) {
                      this.saveDraftOrSubmit(true);
                    }
                  }}
                  className={classes.submit}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </>
        ) : null}
      </Paper>
    );
  }
}

class EngagementsView extends React.Component<any> {
  componentDidMount() {
    this.props.fetchEngagements();
    this.props.fetchInvoices();
  }

  render() {
    const { classes } = this.props;
    const engagement = this.props.engagements.find(
      (e: any) => e.publicId === this.props.engagementPublicId
    );
    if (!engagement) {
      return <Loading></Loading>;
    }
    const invoices = this.props.invoices.filter((invoice: any) => {
      return invoice.engagement.publicId === engagement.publicId;
    });
    return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item sm={5} style={{ minWidth: "600px" }}>
            <Engagement engagement={engagement} isListView={false} />
            <div style={{ marginTop: "10px" }}>
              <InvoicesList invoices={invoices} />
            </div>
          </Grid>
          <Grid item sm={5} style={{ minWidth: "600px" }}>
            <Timesheets
              saveTimesheetDraft={this.props.saveTimesheetDraft}
              classes={classes}
              engagement={engagement}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (storeState: StoreState) => ({
  engagements: storeState.user.engagements,
  invoices: storeState.user.invoices,
});

export default connect(mapStateToProps, {
  fetchEngagements,
  fetchInvoices,
  saveTimesheetDraft,
})(withStyles(styles as any)(EngagementsView));
