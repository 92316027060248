import React from "react";

import dayjs from "../../common/date";

export default function AdminTimesheetsDetailRow(row: { date: string; numberOfHours: string }) {
  const date = dayjs(row.date).utc().format("YYYY-MM-DD");
  return (
    <div key={row.date}>
      {date} - {row.numberOfHours}
    </div>
  );
}