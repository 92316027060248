import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormLabel";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import DatePicker from "react-datepicker";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";

import "react-datepicker/dist/react-datepicker.css";

import { StoreState } from "../../redux/configure-store";
import { createEngagement } from "../admin-actions";

const styles: any = (theme: Theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  avatar: {
    margin: theme.spacing(),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
});

const createInitialErrorState = () => ({
  clientName: "",
  shortDescription: "",
  description: "",
  startDate: "",
  hourlyRateUsd: "",
  numberOfHoursPerWeek: "",
  trackingType: "",
});

class EngagementsCreate extends React.Component<any> {
  state = {
    data: {
      clientName: "",
      shortDescription: "",
      description: "",
      startDate: new Date(),
      hourlyRateUsd: "",
      numberOfHoursPerWeek: "",
      trackingType: "hourly-two-timesheets-per-month",
    },
    errors: createInitialErrorState(),
  };

  onChange = (e: any) => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  handleStartDateChange = (date: Date) => {
    this.setState((prev: any) => ({
      ...this.state,
      data: { ...prev.data, startDate: date },
    }));
  };

  onSubmit = (e: any) => {
    e.preventDefault();
    const { data } = this.state;
    const errors = createInitialErrorState();
    this.setState({ errors });

    const emptyValueErrorMessage = "Value can't be empty";

    if (data.clientName.length === 0) {
      errors.clientName = emptyValueErrorMessage;
    }

    if (data.hourlyRateUsd.length === 0) {
      errors.hourlyRateUsd = emptyValueErrorMessage;
    }

    if (data.shortDescription.length === 0) {
      errors.description = emptyValueErrorMessage;
    }

    if (data.description.length === 0) {
      errors.description = emptyValueErrorMessage;
    }

    if (!data.startDate) {
      errors.startDate = emptyValueErrorMessage;
    }

    if (!data.numberOfHoursPerWeek) {
      errors.numberOfHoursPerWeek = emptyValueErrorMessage;
    }

    this.setState({ errors });

    const hasErrors = Object.values(errors).some(
      (error: string) => error.length !== 0
    );

    if (!hasErrors) {
      this.props.createEngagement({
        clientName: data.clientName,
        userId: this.props.user.id,
        shortDescription: data.shortDescription,
        description: data.description,
        startDate: data.startDate,
        hourlyRateUsd: parseFloat(data.hourlyRateUsd),
        numberOfHoursPerWeek: parseFloat(data.numberOfHoursPerWeek),
        trackingType: data.trackingType,
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { data, errors } = this.state;

    if (!this.props.user || !this.props.user.id) {
      return <>Error. You must select a user</>;
    }
    return (
      <main className={classes.main}>
        <Paper elevation={2} className={classes.paper}>
          <Typography component="h1" variant="h5">
            Create A New Engagement
          </Typography>
          <form className={classes.form} onSubmit={this.onSubmit}>
            <FormControl margin="normal" fullWidth error={!!errors.clientName}>
              <InputLabel htmlFor="clientName" required>
                Client Name
              </InputLabel>
              <Input
                id="clientName"
                name="clientName"
                autoFocus
                autoComplete="off"
                value={data.clientName}
                onChange={this.onChange}
              />
              {errors.clientName && (
                <FormHelperText>{errors.clientName}</FormHelperText>
              )}
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <FormLabel component="legend">Tracking Type</FormLabel>
              <RadioGroup
                aria-label="trackingType"
                name="trackingType"
                value={data.trackingType}
                onChange={this.onChange}
              >
                <FormControlLabel
                  value="hourly-two-timesheets-per-month"
                  control={<Radio />}
                  label="Hourly, two timesheets per month (1st and 16th)"
                />
                <FormControlLabel
                  value="hourly"
                  control={<Radio />}
                  label="Hourly"
                />
                <FormControlLabel
                  value="daysOff"
                  control={<Radio />}
                  label="Days Off"
                />
              </RadioGroup>
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <InputLabel htmlFor="consultantName" required>
                Consultant Name
              </InputLabel>
              <Input
                id="consultantName"
                name="consultantName"
                autoComplete="off"
                disabled={true}
                value={
                  this.props.user.firstName + " " + this.props.user.lastName
                }
                onChange={this.onChange}
              />
            </FormControl>
            <FormControl
              margin="normal"
              fullWidth
              error={!!errors.hourlyRateUsd}
            >
              <InputLabel htmlFor="hourlyRateUsd" required>
                Developer Hourly Rate US$
              </InputLabel>
              <Input
                id="hourlyRateUsd"
                name="hourlyRateUsd"
                value={data.hourlyRateUsd}
                autoComplete="off"
                onChange={this.onChange}
              />
              {errors.hourlyRateUsd && (
                <FormHelperText>{errors.hourlyRateUsd}</FormHelperText>
              )}
            </FormControl>
            <FormControl
              margin="normal"
              fullWidth
              error={!!errors.numberOfHoursPerWeek}
            >
              <InputLabel htmlFor="numberOfHoursPerWeek" required>
                Number of hours per week
              </InputLabel>
              <Input
                id="numberOfHoursPerWeek"
                name="numberOfHoursPerWeek"
                value={data.numberOfHoursPerWeek}
                autoComplete="off"
                onChange={this.onChange}
              />
              {errors.numberOfHoursPerWeek && (
                <FormHelperText>{errors.numberOfHoursPerWeek}</FormHelperText>
              )}
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <FormLabel component="legend" required>
                Start Date
              </FormLabel>
              <DatePicker
                selected={data.startDate}
                onChange={this.handleStartDateChange}
              />
              {errors.startDate && (
                <FormHelperText>{errors.startDate}</FormHelperText>
              )}
            </FormControl>
            <FormControl
              margin="normal"
              fullWidth
              error={!!errors.shortDescription}
            >
              <InputLabel htmlFor="shortDescription" required>
                Short Description
              </InputLabel>
              <Input
                name="shortDescription"
                type="text"
                id="shortDescription"
                autoComplete="off"
                multiline={true}
                rows={1}
                value={data.shortDescription}
                onChange={this.onChange}
              />
            </FormControl>
            <FormControl margin="normal" fullWidth error={!!errors.description}>
              <InputLabel htmlFor="description" required>
                Description
              </InputLabel>
              <Input
                name="description"
                type="text"
                id="description"
                autoComplete="off"
                multiline={true}
                rows={5}
                value={data.description}
                onChange={this.onChange}
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Submit
            </Button>
          </form>
        </Paper>
      </main>
    );
  }
}

const mapStateToProps = (storeState: StoreState) => ({
  isRegisterFailed: storeState.authentication.registerFailed,
});

export default connect(mapStateToProps, { createEngagement })(
  withStyles(styles)(EngagementsCreate)
);
