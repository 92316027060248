import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import { StoreState } from "../../redux/configure-store";
import AdminTimesheetsTable from '../timesheets/AdminTimesheetsTable';
import AdminTimesheetsView from '../timesheets/AdminTimesheetsView';
import { Timesheet } from "../types";
import Loading from '../../common/components/Loading'
import {
  dispatchAdminSetSlectedTimesheets,
  dispatchAdminUnsetSlectedTimesheets
} from '../../admin/admin-actions'
import { calculateTotalHoursForTimesheets } from "../../common/utils"

const styles = (theme: Theme) =>
  createStyles({
    main: {
      width: "auto",
      display: "block", // Fix IE 11 issue.
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
        width: 400,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    root: {
      width: "100%",
      overflowX: "auto",
    },
    table: {
      minWidth: 700,
      marginBottom: "40px",
    },
    paper: {
      marginTop: theme.spacing(3),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
        3
      )}px`,
    },
  });

function AdminEngagementsView(props: any) {
  const {
    classes,
    engagement,
    selectedTimesheetIds,
    allTimesheets,
    navigateToAdminEngagementsCreateInvoice,
    dispatchAdminSetSlectedTimesheets: _dispatchAdminSetSlectedTimesheets,
    dispatchAdminUnsetSlectedTimesheets: _dispatchAdminUnsetSlectedTimesheets,
  } = props;

  const [timesheets, setTimesheets] = useState<Timesheet[]>([]);
  const [selectedTimesheets, setSelectedTimesheets] = useState<Timesheet[]>([]);
  const [totalNumberOfHours, setTotalNumberOfHours] = useState<string>('0');

  useEffect(() => {
    _dispatchAdminUnsetSlectedTimesheets();
  }, [_dispatchAdminUnsetSlectedTimesheets])

  useEffect(() => {
    if (!engagement) {
      return;
    }
    setTimesheets(allTimesheets.filter(
      (t: Timesheet) => t.engagement.id === engagement.id
    ));
  }, [engagement, allTimesheets]);

  useEffect(() => {
    if (!engagement || !timesheets.length) {
      return;
    }

    if (!selectedTimesheetIds.length) {
      setSelectedTimesheets([]);
      setTotalNumberOfHours('0');
      return;
    }
    const _selectedTimesheets = selectedTimesheetIds.map((id: number) => timesheets.find((t: Timesheet) => t.id === id));
    setSelectedTimesheets(_selectedTimesheets);

    setTotalNumberOfHours(calculateTotalHoursForTimesheets(_selectedTimesheets));
  }, [selectedTimesheetIds, timesheets, engagement]);

  if (!engagement) {
    return <Loading></Loading>
  }

  return (
    <>
      <Grid container spacing={4} style={{ marginBottom: "20px" }}>
        <Grid item sm={6}>
          <Typography variant="h4" gutterBottom component="h2">
            Engagement
          </Typography>
          <Typography variant="subtitle1" gutterBottom component="p">
            Developer: {engagement.user.firstName} {engagement.user.lastName}
            <br />
            Client Name: {engagement.clientName}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            style={{ width: "175px" }}
            onClick={() => {
              navigateToAdminEngagementsCreateInvoice(engagement.id)
            }}
            className={classes.submit}
          >
            Create Invoice
            <br />
            {
              selectedTimesheetIds.length > 0
                ? `(${selectedTimesheetIds.length} Timesheets Selected)`
                : ""
            }
          </Button>
        </Grid>
        <Grid item sm={3}>
          <div style={{ height: "225px", overflow: "scroll" }}>
            {!selectedTimesheets.length
              ?
              (<div>
                Click on timesheet to view hours breakdown...
              </div>)
              :
              (<>
                <p>
                  <b>Format: {selectedTimesheets[0].timeFormat}</b>
                </p>
                <p><b>Total Hours: {totalNumberOfHours}</b></p>
                {selectedTimesheets.map((timesheet: Timesheet) => (
                  <AdminTimesheetsView
                    key={timesheet.id}
                    isHideHeader={true}
                    selectedTimesheet={timesheet}
                  />
                ))}
              </>)
            }
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={4} style={{ marginBottom: "20px", marginTop: "-30px" }}>
        <Grid item sm={6}>
          <Typography variant="h4" component="h2">
            Timesheets
          </Typography>
        </Grid>
        <div className={classes.tableContainer}>
          <Paper elevation={2} className={classes.root}>
            <AdminTimesheetsTable
              selectedTimesheetIds={selectedTimesheetIds}
              onRowClick={(timesheetId: number) => {
                const timesheet = timesheets.find(t => t.id === timesheetId);
                if (selectedTimesheets.length && timesheet?.timeFormat !== selectedTimesheets[0].timeFormat) {
                  alert('Error. Selected timesheets must have the same time format. You cannot select a `decimal` and `minutes` timesheet.')
                  return;
                }
                const ids = selectedTimesheetIds.includes(timesheetId)
                  ? selectedTimesheetIds.filter((id: number) => id !== timesheetId)
                  : [...selectedTimesheetIds, timesheetId].sort()
                _dispatchAdminSetSlectedTimesheets(ids)
              }}
              timesheets={timesheets}
            >
            </AdminTimesheetsTable>
          </Paper>
        </div>
      </Grid>
    </>
  );
}

const mapStateToProps = (storeState: StoreState) => ({
  allTimesheets: storeState.admin.timesheets,
  selectedTimesheetIds: storeState.admin.selectedTimesheetIds
});

export default connect(
  mapStateToProps,
  {
    dispatchAdminSetSlectedTimesheets,
    dispatchAdminUnsetSlectedTimesheets,
  }
)(withStyles(styles)(AdminEngagementsView));
