import { routePaths, setPathId } from "../routing/route-paths";
import { push } from "connected-react-router";

export const navigateToDashboardPage = () => (dispatch: Function) =>
  dispatch(push(routePaths.dashboard.root));

export const navigateToPasswordPage = () => (dispatch: Function) =>
  dispatch(push(routePaths.auth.changePassword));

export const navigateToEngagementsViewPage =
  (engagementId: number) => (dispatch: Function) =>
    dispatch(
      push(setPathId(routePaths.engagements.engagementsView, engagementId))
    );

/** Admin */

export const navigateToAdminPage = () => (dispatch: Function) =>
  dispatch(push(routePaths.admin.root));

export const navigateToAdminUsers = () => (dispatch: Function) =>
  dispatch(push(routePaths.admin.users));

export const navigateToAdminEngagements = () => (dispatch: Function) =>
  dispatch(push(routePaths.admin.engagements));

export const navigateToAdminInvoices = () => (dispatch: Function) =>
  dispatch(push(routePaths.admin.invoices));

export const navigateToAdminTimesheetsViewPage =
  (timesheetId: number) => (dispatch: Function) =>
    dispatch(push(setPathId(routePaths.admin.timesheetsView, timesheetId)));

export const navigateToAdminUsersCreateEngagement =
  (userId: number) => (dispatch: Function) =>
    dispatch(push(setPathId(routePaths.admin.usersCreateEngagement, userId)));

export const navigateToAdminEngagementsCreateInvoice =
  (engagementId: number) => (dispatch: Function) =>
    dispatch(push(setPathId(routePaths.admin.engagementsCreateInvoice, engagementId)));

export const navigateToAdminUsersViewPage =
  (userId: number) => (dispatch: Function) =>
    dispatch(push(setPathId(routePaths.admin.usersView, userId)));

export const navigateToAdminEngagementsViewPage =
  (engagementId: number) => (dispatch: Function) =>
    dispatch(push(setPathId(routePaths.admin.engagementsView, engagementId)));
