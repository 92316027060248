import React from "react";
import Button from "@material-ui/core/Button";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import { formatShortDate } from "../../common/utils";

function AdminEngagementsTableRow(props: any) {
  const {
    engagement,
    endEngagement,
    navigateToAdminEngagementsViewPage,
    lastTimesheetSubmittedAt,
  } = props;

  return (
    <TableRow
      style={{ cursor: "pointer" }}
      key={engagement.id}
      onClick={() => navigateToAdminEngagementsViewPage(engagement.id)}
    >
      <TableCell component="th" scope="row">
        {engagement.clientName}
      </TableCell>
      <TableCell>
        {engagement.user.firstName} {engagement.user.lastName}
      </TableCell>
      <TableCell>{engagement.currency}</TableCell>
      <TableCell>{engagement.hourlyRate}</TableCell>
      <TableCell>{engagement.trackingType}</TableCell>
      <TableCell>{engagement.numberOfHoursPerWeek}</TableCell>
      <TableCell>{engagement.shortDescription}</TableCell>
      <TableCell>{engagement.description}</TableCell>
      <TableCell>{formatShortDate(lastTimesheetSubmittedAt)}</TableCell>
      <TableCell>{formatShortDate(engagement.startDate)}</TableCell>
      <TableCell>
        {engagement.endDate ? (
          formatShortDate(engagement.endDate)
        ) : (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={(e: any) => {
              e.stopPropagation();
              endEngagement(engagement.publicId);
            }}
            style={{ width: "auto" }}
          >
            End Engagement
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
}

export default AdminEngagementsTableRow;
