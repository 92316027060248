import React from "react";
import { isNil } from "lodash";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import {
  createStyles,
  Paper,
  Typography,
  LinearProgress,
  Grid,
} from "@material-ui/core";
import moment from "moment";

import { Invoice } from "../admin/types";
import { formatCurrencyAmount } from "../common/utils";

const red = "#F44336";
const green = "#00D88E";
const blue = "#2196F3";
const yellow = "#FF9800";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
    },
    primaryLabel: {
      fontSize: theme.typography.h6.fontSize,
      // textTransform: "uppercase"
    },
    secondaryLabel: {
      color: theme.palette.grey[500],
    },
    totalLeads: {
      display: "flex",
      alignItems: "flex-end",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    totalLeadsValue: {
      fontSize: theme.typography.h3.fontSize,
    },
    durationWrapper: {
      textAlign: "center",
      marginLeft: theme.spacing(),
      marginBottom: theme.spacing(1.5),
      color: theme.palette.grey[500],
      paddingBottom: "2px",
    },
    statsContainer: {
      borderStyle: "solid",
      borderWidth: 0,
      borderTopWidth: 1,
      borderColor: theme.palette.grey[300],
      paddingTop: theme.spacing(),
    },
    statsGridContainer: {
      marginTop: 0,
      marginBottom: 0,
    },
    statsGridItem: {
      paddingTop: `${theme.spacing()}px !important`,
      paddingBottom: `${theme.spacing()}px !important`,
    },
    statsNumbers: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    statsNumberValue: {
      fontSize: theme.typography.h4.fontSize,
      fontWeight: "bold",
    },
    statsPercentValue: {
      fontSize: theme.typography.h5.fontSize,
    },
    textGrey: {
      color: theme.palette.grey[800],
    },
    textRed: {
      color: red,
    },
    textGreen: {
      color: green,
    },
    textBlue: {
      color: blue,
    },
    textYellow: {
      color: yellow,
    },
    barRoot: {
      marginTop: theme.spacing(),
      marginBottom: theme.spacing(),
      height: theme.spacing(0.75),
      borderRadius: theme.spacing(0.5),
      backgroundColor: theme.palette.grey[300],
    },
    barGreen: {
      borderRadius: theme.spacing(0.5),
      backgroundColor: green,
    },
    barRed: {
      borderRadius: theme.spacing(0.5),
      backgroundColor: red,
    },
    barBlue: {
      borderRadius: theme.spacing(0.5),
      backgroundColor: blue,
    },
    barYellow: {
      borderRadius: theme.spacing(0.5),
      backgroundColor: yellow,
    },
    statsLabel: {
      color: theme.palette.grey[500],
    },
  });

const formatShortDate = (date: Date): string => moment(date).format("ll");
const formatInteger = (integer: number): string => String(integer);

const shortDateOrLoading = (date: Date | undefined): string =>
  date ? formatShortDate(date) : "--";
const integerOrLoading = (integer: number | undefined): string =>
  isNil(integer) ? "--" : formatInteger(integer as number);

class DashboardEarningsStats extends React.Component<any> {
  render() {
    const { classes, engagements, invoices, user } = this.props;

    const numberOfHoursBooked = engagements.reduce(
      (prev: number, curr: any) => {
        if (curr.endDate) {
          return prev;
        }
        return prev + curr.numberOfHoursPerWeek;
      },
      0
    );

    // TODO handle different currencies
    const currency = invoices && invoices.length ? invoices[0].currency : "USD";
    const totalEarningsAmount = (invoices || []).reduce(
      (total: number, invoice: Invoice) => {
        return total + invoice.totalPaymentAmount;
      },
      0
    );

    return (
      <Paper elevation={2} className={classes.root}>
        <Typography className={classes.primaryLabel}>Earnings</Typography>
        <Typography className={classes.secondaryLabel}>
          Total Earnings From All Engagements
        </Typography>
        <div className={classes.totalLeads}>
          <Typography
            className={classNames(classes.totalLeadsValue, classes.textGreen)}
          >
            {integerOrLoading(engagements.length)}
          </Typography>
          <Typography
            className={classNames(classes.durationWrapper, classes.textGrey)}
          >
            Completed and Active Engagements: &nbsp;
            <span className={classes.textGreen}>
              {shortDateOrLoading(user.createdAt)} -{" "}
              {shortDateOrLoading(new Date())}
            </span>
          </Typography>
        </div>
        <div className={classes.statsContainer}>
          <Grid className={classes.statsGridContainer} container spacing={5}>
            <Grid className={classes.statsGridItem} item xs={12} sm={5}>
              <div className={classes.statsNumbers}>
                <Typography
                  className={classNames(
                    classes.statsNumberValue,
                    classes.textBlue
                  )}
                >
                  {formatCurrencyAmount(totalEarningsAmount, currency)}
                </Typography>
              </div>
              <LinearProgress
                classes={{
                  root: classes.barRoot,
                  barColorPrimary: classes.barBlue,
                }}
                variant="determinate"
                value={100}
              />
              <Typography className={classes.statsLabel}>Earnings</Typography>
            </Grid>
            <Grid className={classes.statsGridItem} item xs={12} sm={5}>
              <div className={classes.statsNumbers}>
                <Typography
                  className={classNames(
                    classes.statsNumberValue,
                    classes.textYellow
                  )}
                >
                  {numberOfHoursBooked} hours
                </Typography>
              </div>
              <LinearProgress
                classes={{
                  root: classes.barRoot,
                  barColorPrimary: classes.barYellow,
                }}
                variant="determinate"
                value={100}
              />
              <Typography className={classes.statsLabel}>
                Number of Hours per Week
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Paper>
    );
  }
}

export default withStyles(styles, { withTheme: true })(DashboardEarningsStats);
