import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import dayjs from "../common/date";

import { StoreState } from "../redux/configure-store";

const styles = (theme: Theme) => ({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const formatShortDate = (date: Date): string =>
  dayjs(date).utc().format("YYYY-MM-DD");

const shortDateOrLoading = (date: Date | undefined): string =>
  date ? formatShortDate(date) : "--";

class Engagement extends React.Component<any> {
  render() {
    const { classes, engagement, isListView, onEngagementClick } = this.props;
    return (
      <Card
        onClick={() => {
          if (onEngagementClick) {
            onEngagementClick(engagement.publicId);
          }
        }}
        className={classes.root}
        style={{ cursor: isListView ? "pointer" : "" }}
        variant="outlined"
      >
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            {engagement.shortDescription}
          </Typography>
          <Typography variant="h5" component="h2">
            {engagement.clientName}
          </Typography>
          <Typography className={classes.pos} color="textSecondary">
            Started {shortDateOrLoading(engagement.startDate)} |{" "}
            {engagement.numberOfHoursPerWeek} hrs/week | {engagement.currency}
            {engagement.hourlyRate} /hr
          </Typography>
          {!isListView && (
            <Typography variant="body2" component="p">
              {engagement.description}
            </Typography>
          )}
        </CardContent>
        {isListView && (
          <CardActions>
            <Button size="small">View</Button>
          </CardActions>
        )}
      </Card>
    );
  }
}

const mapStateToProps = (storeState: StoreState) => ({});

export default connect(mapStateToProps, {})(withStyles(styles)(Engagement));
