import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { createStyles, Paper, Typography } from "@material-ui/core";
import List from "@material-ui/core/List";

import EngagementListItem from "../engagements/EngagementListItem";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
    },
    primaryLabel: {
      fontSize: theme.typography.h6.fontSize,
      // textTransform: "uppercase"
    },
    secondaryLabel: {
      color: theme.palette.grey[500],
    },
    engagementPaper: {
      padding: theme.spacing(3),
      margin: "5px",
    },
    engagement: {
      cursor: "pointer",
      display: "flex",
      alignItems: "flex-end",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    durationWrapper: {
      textAlign: "center",
      marginLeft: theme.spacing(),
      marginBottom: theme.spacing(1.5),
      color: theme.palette.grey[500],
      paddingBottom: "2px",
    },

    noEngagements: {
      display: "flex",
      alignItems: "flex-end",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    statsContainer: {
      borderStyle: "solid",
      borderWidth: 0,
      borderTopWidth: 1,
      borderColor: theme.palette.grey[300],
      paddingTop: theme.spacing(),
    },
    statsGridContainer: {
      marginTop: 0,
      marginBottom: 0,
    },
    statsGridItem: {
      paddingTop: `${theme.spacing()}px !important`,
      paddingBottom: `${theme.spacing()}px !important`,
    },
    statsNumbers: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    statsNumberValue: {
      fontSize: theme.typography.h4.fontSize,
      fontWeight: "bold",
    },
    statsPercentValue: {
      fontSize: theme.typography.h5.fontSize,
    },
    textGrey: {
      color: theme.palette.grey[800],
    },
    barRoot: {
      marginTop: theme.spacing(),
      marginBottom: theme.spacing(),
      height: theme.spacing(0.75),
      borderRadius: theme.spacing(0.5),
      backgroundColor: theme.palette.grey[300],
    },
    statsLabel: {
      color: theme.palette.grey[500],
    },
  });

class DashboardEngagements extends React.Component<any> {
  render() {
    const { classes, engagements, onEngagementClick } = this.props;

    return (
      <Paper elevation={3} className={classes.root}>
        <Typography
          className={classes.primaryLabel}
          style={{ marginBottom: "10px" }}
        >
          Engagements
        </Typography>
        {(!engagements || !engagements.length) && (
          <div className={classes.noEngagements}>
            <Typography>You do not have any engagements yet.</Typography>
          </div>
        )}
        {engagements && engagements.length ? (
          <List className={classes.root} style={{ padding: "0px" }}>
            {engagements.map((engagement: any) => (
              <div key={engagement.id}>
                <EngagementListItem
                  onEngagementClick={onEngagementClick}
                  engagement={engagement}
                />
              </div>
            ))}
          </List>
        ) : null}
      </Paper>
    );
  }
}

export default withStyles(styles, { withTheme: true })(DashboardEngagements);
