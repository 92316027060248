import httpService from "../http-service";
import { Engagement, Invoice } from "../common/interfaces";

const mapResponseToPayload = (res: any) => res.data;

const { axios, axiosWithoutInterceptors } = httpService;

const api = {
  admin: {
    createEngagement: (engagement: Engagement) =>
      axios
        .post("/api/admin/engagements", engagement)
        .then(mapResponseToPayload),
    createInvoice: (invoice: Invoice) =>
      axios.post("/api/admin/invoices", invoice).then(mapResponseToPayload),
    endEngagement: (publicId: string) =>
      axios
        .put("/api/admin/engagements/end", { publicId })
        .then(mapResponseToPayload),
    fetchInvoicesForAdmin: () =>
      axios.get(`/api/admin/invoices`).then(mapResponseToPayload),
    fetchTimesheetsForAdmin: () =>
      axios.get(`/api/admin/timesheets`).then(mapResponseToPayload),
    fetchUsersForAdmin: () =>
      axios.get(`/api/admin/users`).then(mapResponseToPayload),
    fetchAppStates: () =>
      axios.get(`/api/admin/app-states`).then(mapResponseToPayload),
    fetchEngagements: () =>
      axios.get(`/api/admin/engagements`).then(mapResponseToPayload),
    fetchKpis: () =>
      axios.get(`/api/admin/kpis`).then(mapResponseToPayload),
    markInvoiceAsPaid: (publicId: string) =>
      axios
        .put(`/api/admin/invoices/paid`, { publicId })
        .then(mapResponseToPayload),
    updateUserForAdmin: (updatedUserForm: any) =>
      axios
        .put("/api/admin/user", { updatedUserForm })
        .then(mapResponseToPayload),
    setAppStateWorkerProcessLock: (isLockWorkerProcess: boolean) =>
      axios
        .put(`/api/admin/app-states/worker-process-lock`, {
          isLockWorkerProcess,
        })
        .then(mapResponseToPayload),
  },

  auth: {
    register: (
      emailAddress: string,
      password: string,
      firstName: string,
      lastName: string
    ) =>
      axiosWithoutInterceptors
        .post("/api/authentication/register", {
          emailAddress,
          password,
          firstName,
          lastName,
        })
        .then(mapResponseToPayload),

    login: (emailAddress: string, password: string) =>
      axiosWithoutInterceptors
        .post("/api/authentication/login", {
          emailAddress,
          password,
        })
        .then(mapResponseToPayload),

    logout: () => axios.post("/api/authentication/logout", {}),

    validateSessionCookie: () =>
      axios.post("/api/authentication/validate", {}).then(mapResponseToPayload),

    changePassword: (
      currentPassword: string,
      newPassword: string,
      confirmNewPassword: string
    ) =>
      axios
        .put("/api/authentication/password", {
          currentPassword,
          newPassword,
          confirmNewPassword,
        })
        .then(mapResponseToPayload),
  },

  users: {
    fetchInvoice: (publicId: string) =>
      axios.get(`/api/users/invoices/${publicId}`).then(mapResponseToPayload),
    fetchInvoices: () =>
      axios.get("/api/users/invoices").then(mapResponseToPayload),
    updateTimesheet: (
      timesheetId: number,
      timesheetDetails: any,
      notes: string,
      timeFormat: "decimals" | "minutes",
      isSubmitting = false
    ) =>
      axios
        .put("/api/users/timesheets", {
          timesheetId,
          timesheetDetails,
          notes,
          timeFormat,
          isSubmitting,
        })
        .then(mapResponseToPayload),
    fetchEngagements: () =>
      axios.get("/api/users/engagements").then(mapResponseToPayload),
    updateUserSettings: (userId: number) =>
      axios
        .put("/api/users", {
          userId,
        })
        .then(mapResponseToPayload),
  },
};

export default api;
