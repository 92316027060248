import React from "react";
import Typography from "@material-ui/core/Typography";

import AdminTimesheetsDetailRow from "./AdminTimesheetsDetailRow";

function AdminTimesheetsView(props: any) {
  const { selectedTimesheet, isHideHeader = false } = props;

  if (!selectedTimesheet) {
    return null;
  }

  return (
    <div>
      {isHideHeader ?
        <></>
        :
        <>
          <Typography variant="h4" gutterBottom component="h2">
            Timesheet {selectedTimesheet.id}
          </Typography>
          <p>
            <b>format: {selectedTimesheet.timeFormat}</b>
          </p>
        </>
      }
      {selectedTimesheet.details.map(AdminTimesheetsDetailRow)}
    </div>
  );
}

export default AdminTimesheetsView;
